import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import { tail } from "lodash";
import {
  fetchContentsList,
  fetchContentsDetail,
  fetchContentsReference,
  contentsDelete,
  contentsActions,
  downloadCsvContentsAccess,
  fetchContentsCsvFavoriteItemCreate,
  fetchContentsCsvFavoriteItemList,
} from '../../slices/contentsSlice';
import { contentsColumns } from '../../constants/columns';
import Icons from '../../constants/Icons';
import PageTitle from '../../components/pageTitle';
import TcButton from '../../components/tcButton';
import TcRadio from '../../components/tcCheckbox/tcRadio';
import TcCheckbox from '../../components/tcCheckbox';
import TcTable from '../../components/tcTable';
import ColumnTitle from '../../components/columnTitle';
import ContentsModal from '../../components/contentsModal';
import ContentsReferenceModal from '../../components/contentsModal/reference';
import DeleteModal from '../../components/modal/deleteModal';
import TableTitle from '../../components/tableTitle';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import {
  pageAccessCsvFavoriteItemOptions,
  pageAccessCsvDownloadModeOptions, csvFavoriteItemTypes,
} from '../../constants/options';
import CsvDownloadModal from '../../components/modal/pageAccessCsvDownloadModal';
import ResultModal from '../../components/modal/pageAccessResultModal';
import { fetchPageAccessResult } from '../../slices/contentsSlice';

const Contents = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const {
    searchParams,
    contentsList,
    total,
    currentContents,
    deleteModalVisible,
    deleteContents,
    csvDownloadModalVisible,
    resultModalVisible,
    favoriteItem,
  } = useSelector(state => state.contents);

  const { selectOptionList } = useSelector(state => state.name);

  const [favoriteItems, setFavoriteItems] = useState(pageAccessCsvFavoriteItemOptions);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,

    onSubmit: values => {
      const { start_post_period, end_post_period, ...rest } = values;
      const params = {
        ...rest,
        start_post_period: dateFormat(start_post_period, 'YYYY/MM/DD'),
        end_post_period: dateFormat(end_post_period, 'YYYY/MM/DD'),
      };

      dispatch(contentsActions.saveSearchParams(params));
    },
  });

  useEffect(
    () => dispatch(contentsActions.clear()),
    []
  );

  useUpdateEffect(
    () => dispatch(fetchContentsList(searchParams)),
    [searchParams]
  );

  useEffect(() => {
    dispatch(fetchContentsCsvFavoriteItemList({
      screen_type: csvFavoriteItemTypes['contents'],
    }));
    dispatch(contentsActions.saveSearchParams(null));
    return () => dispatch(contentsActions.clear());
  }, [dispatch]);

  const columns = [
    ...contentsColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => dispatch(contentsActions.saveSearchParams({ sort }))}
        />
      ),
      dataIndex: col.sortField,
      render: (value) => {
        if (col.sortField === 'slider') {
          for (const selectOption of selectOptionList) {
            if (selectOption.field_name === 'スライダー') {
              for (const option of selectOption.select_option) {
                if (option.value === value) {
                  return option.label;
                }
              }
            }
          }
        } else if (col.sortField === 'contents_type') {
          for (const selectOption of selectOptionList) {
            if (selectOption.field_name === 'コンテンツ種別') {
              for (const option of selectOption.select_option) {
                if (option.value === value) {
                  return option.label;
                }
              }
            }
          }
        }
        return value;
      }
    })),
    {
      title: '結果',
      align: 'center',
      width: '130px',
      render: current => {
        return current.deleted_flg === '0' ? (
          <>
            <div className="summary-area">
              <div className="summary-item-area">
                <TcButton
                  text="結果表示"
                  theme="white"
                  style={{
                    width: 100,
                  }}
                  icon={<Icons.IconResult className="btn-icon" />}
                  onClick={() => dispatch(fetchPageAccessResult({
                    type: '1',
                    contents_code: current.contents_code,
                    contents_type: current.contents_type,
                  }))}
                />
              </div>
              <TcButton
                text="CSV出力"
                theme="white"
                style={{
                  width: 100,
                }}
                icon={<Icons.IconUpload className="btn-icon" />}
                onClick={() => dispatch(contentsActions.openCsvDownloadModal(current))}
              />
            </div>
          </>
        ) : (
          <div className="summary-area">
            <div className="summary-item-area">
              <button className="disable-btn">
                <Icons.IconResultGy className="btn-icon" /> {'結果表示'}
              </button>
            </div>
            <button className="disable-btn">
              <Icons.IconDownloadGy className="btn-icon" /> {'CSV出力'}
            </button>
          </div>
        );
      },
    },
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current => {
        return current.deleted_flg === '0' ? (
          <Icons.IconEdit
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchContentsDetail(current))}
          />
        ) : (
          <Icons.IconEditGy
            className="tc-disabled-icon"
            height={'20px'}
            width={'20px'}
          />
        );
      },
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current => {
        return current.deleted_flg === '0' ? (
          <Icons.IconTrash
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(contentsActions.openDeleteModal(current))}
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchContentsReference(current))}
          />
        );
      },
    },
  ];

  return (
    <div className="content-body management-page contents" ref={parent}>
      <PageTitle
        title="コンテンツ"
        icon={<Icons.IconContents className="contents-icon" />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1100}>
          <TextBox formik={formik} label="タイトル" fieldName="title" />
          <TextBox formik={formik} label="サブタイトル" fieldName="sub_title" />
          <SelectBox
            formik={formik}
            label="スライダー"
            fieldName="slider"
            options={
              selectOptionList?.filter(s => s.field_name === 'スライダー')?.[0]
                ?.select_option ?? []
            }
          />
          <DateTimeRangePicker
            formik={formik}
            label="掲載期間"
            fromFieldName="start_post_period"
            toFieldName="end_post_period"
          />
          <SelectBox
            formik={formik}
            label="コンテンツ種別"
            fieldName="contents_type"
            options={
              selectOptionList?.filter(
                s => s.field_name === 'コンテンツ種別'
              )?.[0]?.select_option ?? []
            }
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={formik.values.deleted_flg === '1'}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'deleted_flg',
                formik.values.deleted_flg === '1' ? '0' : '1'
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(contentsActions.openEditModal(null))}
      />
      <TcTable
        columns={columns}
        dataSource={contentsList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(contentsActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <ContentsModal />
      <ContentsReferenceModal />
      <DeleteModal
        modalVisible={deleteModalVisible}
        onCancel={() => dispatch(contentsActions.closeDeleteModal())}
        item={'コンテンツ'}
        itemValue={deleteContents?.contents_code}
        onOk={() => dispatch(contentsDelete(deleteContents?.contents_code))}
      />
      <CsvDownloadModal
        line={3}
        options={favoriteItems}
        modalVisible={csvDownloadModalVisible}
        favoriteItem={favoriteItem}
        requiredText={null}
        contentsMode={formik.values?.mode}
        searchArea={
          <div className="csv-modal-date-container">
            <TcRadio
              options={pageAccessCsvDownloadModeOptions}
              formik={formik}
              fieldName={'mode'}
              width={700}
              onChange={v => {
                if (v === '1' || v === '3') {
                  setFavoriteItems(tail(pageAccessCsvFavoriteItemOptions));
                } else {
                  setFavoriteItems(pageAccessCsvFavoriteItemOptions);
                }
                handlerFormikFieldChange(formik, 'mode', v);
              }}
              isRadio={true}
            />
          </div>
        }
        onFavoriteItem={checkedList => {
          const initial = pageAccessCsvFavoriteItemOptions.reduce(
            (acc, item) => ({
              ...acc,
              favorite: { ...acc.favorite, [item.value]: '0' },
            }),
            {
              screen_type: csvFavoriteItemTypes['contents'],
              favorite: {},
            }
          );

          const param = checkedList?.reduce(
            (acc, item) => ({
              ...acc,
              favorite: { ...acc.favorite, [item]: '1' },
            }),
            initial
          );

          dispatch(fetchContentsCsvFavoriteItemCreate(param));
        }}
        onClose={() => dispatch(contentsActions.closeCsvDownloadModal())}
        onDownload={(mode, checkedList) => {
          dispatch(
            downloadCsvContentsAccess({
              csv_mode: formik.values.mode,
              screen_type: '14',
              columns: checkedList,
              type: '1',
              contents_code: currentContents?.contents_code,
              contents_type: currentContents?.contents_type,
            })
          );
        }}
      />
      <ResultModal
        type={'1'}
        line={3}
        modalVisible={resultModalVisible}
        onClose={() => dispatch(contentsActions.closeResultModal())}
      />
    </div>
  );
};

export default Contents;
