import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment';
import qs from 'qs';

const initialSearchParams = {
  title: null,
  sub_title: null,
  slider: null,
  start_post_period: null,
  end_post_period: null,
  contents_type: null,
  deleted_flg: '0',
  offset: 0,
  count: 10,
  sort: 'contents_code_desc',
  mode: "0",
};

const initialState = {
  searchParams: initialSearchParams,
  contentsList: [],
  total: 0,
  deleteModalVisible: false,
  editModalVisible: false,
  referenceModalVisible: false,
  deleteContents: null,
  currentContents: null,
  referenceContents: null,
  csvDownloadModalVisible: false,
  resultModalVisible: false,
  pageAccessResult: null,
  favoriteItem: [],
};

export const fetchContentsList = createAsyncThunk(
  'fetchContentsList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('contents/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchContentsDetail = createAsyncThunk(
  'fetchContentsDetail',
  async params =>
    await axios.get('contents/detail', {
      params: { contents_code: params.contents_code },
    })
);

export const fetchContentsReference = createAsyncThunk(
  'fetchContentsReference',
  async params =>
    await axios.get('contents/detail', {
      params: { contents_code: params.contents_code },
    })
);

export const createFormData = params => {
  const formData = new FormData();
  formData.append('upload_file', params.upload_file);
  formData.append(
    'jsonValue',
    new Blob([JSON.stringify(params)], { type: 'application/json' })
  );
  return formData;
};

export const contentsCreate = createAsyncThunk(
  'contentsCreate',
  async (params, { dispatch }) => {
    await axios.post('contents/create', createFormData(params), {
      headers: { 'content-type': 'multipart/form-data' },
      isFileUpload: true,
    });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const contentsUpdate = createAsyncThunk(
  'contentsUpdate',
  async (params, { dispatch }) => {
    await axios.post('contents/update', createFormData(params), {
      headers: { 'content-type': 'multipart/form-data' },
      isFileUpload: true,
    });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const contentsDelete = createAsyncThunk(
  'contentsDelete',
  async params => await axios.post('contents/delete', { contents_code: params })
);

export const fetchContentsCsvFavoriteItemList = createAsyncThunk(
  'fetchContentsCsvFavoriteItemList',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchContentsCsvFavoriteItemCreate = createAsyncThunk(
  'fetchContentsCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post(
      'csv/favorite_item/create',
      params
    );
    updateFavoriteRes?.code === '0' &&
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));
    return params.favorite;
  }
);

export const fetchPageAccessResult = createAsyncThunk(
  'fetchPageAccessResult',
  async params => await axios.get('sum/contents_access', { params })
);

export const downloadCsvContentsAccess = createAsyncThunk(
  'downloadCsvContentsAccess',
  async (params, { dispatch }) => {
    const result = await axios.get('csv/download', {
      params,
      paramsSerializer: params => qs.stringify(params, { allowDots: true }),
    });
    if (!!!result) {
      dispatch(globalActions.showCommonErrorModal());
    }
    return result;
  }
);

export const contentsSlice = createSlice({
  name: 'contentsSlice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      deleteContents: payload,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
    }),
    openEditModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentContents: null,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceModalVisible: false,
      currentContents: null,
    }),
    openCsvDownloadModal: (state, { payload }) => ({
      ...state,
      currentContents: payload,
      csvDownloadModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    openResultModal: (state, { payload }) => ({
      ...state,
      resultModalVisible: true,
    }),
    closeResultModal: state => ({
      ...state,
      resultModalVisible: false,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchContentsList.fulfilled]: (state, { payload }) => {
      state.contentsList = payload.item;
      state.total = payload.total_count;
    },
    [fetchContentsDetail.fulfilled]: (state, { payload }) => {
      // const isRichTextEditor = typeof payload.item.text === 'object'
      state.editModalVisible = true;
      state.currentContents = {
        ...payload.item,
        richText: payload.item?.rich_text
          ? payload.item.rich_text
            .split('-----separator-----')
            .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
          : [],
        file_type: payload.item?.list_type ?? "0",
      };
    },
    [fetchContentsReference.fulfilled]: (state, { payload }) => {
      state.referenceModalVisible = true;
      state.referenceContents = {
        ...payload.item,
        rich_text: payload.item?.rich_text
          ? payload.item.rich_text
            .split('-----separator-----')
            .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
          : [],
      };
    },
    [contentsCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
    }),
    [contentsUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
      currentContents: null,
    }),
    [contentsDelete.fulfilled]: state => ({
      ...state,
      deleteModalVisible: false,
      searchParams: { ...state.searchParams },
      deleteContents: null,
    }),
    [fetchContentsCsvFavoriteItemList.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchContentsCsvFavoriteItemCreate.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload },
    }),
    [downloadCsvContentsAccess.fulfilled]: (_, { payload }) => {
      if (payload && typeof payload === 'string') {
        downloadFile(
          payload,
          `コンテンツアクセス集計_${moment().format('YYYYMMDDHHmmss')}.csv`
        );
      }
    },
    [fetchPageAccessResult.fulfilled]: (state, { payload }) => {
      state.pageAccessResult = payload.item;
      state.resultModalVisible = true;
    },
  },
});

export const contentsActions = contentsSlice.actions;

export default contentsSlice.reducer;
