import { Image, Modal } from 'antd';
import ModalTitle from '../modalTitle';
import { useDispatch, useSelector } from 'react-redux';
import { adventCalendarActions } from '../../slices/adventCalendarSlice';
import './style.scss';
import moment from 'moment/moment';
import TcButton from '../tcButton';
import React from 'react';
import RichTextEditor from '../richTextEditor';
import { toSlateState } from '../richTextEditor/utils/serializer';
import VideoImageTagElement from "../videoImageTagElement";
import {convertImageFieldName} from "../../utils/fnUtil";

const AdventCalendarReferenceModal = () => {
  const dispatch = useDispatch();

  const format = (date, format) => (date ? moment(date)?.format(format) : null);

  const { referenceModalVisible, currentAdventCalendar, initialValues } =
    useSelector(state => state.adventCalendar);

  const onBack = () => dispatch(adventCalendarActions.closeReferenceModal());

  const {
    advent_calendar_code,
    banner_image_url,
    image_url,
    advent_calendar_name,
    advent_calendar_text,
    advent_calendar_template,
    display_start_date,
    display_end_date,
    advent_calendar_start_date,
    advent_calendar_end_date,
    advent_calendar_gifts,
    public_notice_flag,
    advent_calendar_notice_flag,
  } = currentAdventCalendar || initialValues;

  return (
    <Modal
      className={'tc-modal'}
      open={referenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content advent-calendar-reference-modal">
        <ModalTitle title={'アドベントカレンダー参照'} />

        <div className={'input-area'}>
          <div className={'display-text-area'}>
            <span className={'pre-label'}>アドベントカレンダーCD</span>
            <span className={'pre-text'}>{advent_calendar_code}</span>
          </div>

          <div className={'display-img-area'}>
            <span className={'pre-label'}>{convertImageFieldName(1, "バナー画像")}</span>
            <VideoImageTagElement
              imageUrl={banner_image_url}
              height={150}
              width={150}
            />
          </div>

          <div className={'display-img-area'}>
            <span className={'pre-label'}>背景画像</span>
            <VideoImageTagElement
              imageUrl={image_url}
              height={150}
              width={150}
            />
          </div>

          <div className={'display-text-area'}>
            <span className={'pre-label '}>アドベントカレンダー名</span>
            <span className={'pre-text'}>{advent_calendar_name}</span>
          </div>

          <div className="text-area" style={{ height: 'unset', marginTop: 0 }}>
            <span
              className={'pre-label editor-detail-title'}
              style={{ width: 150, flexShrink: 0 }}
            >
              アドベントカレンダー説明
            </span>
            <div style={{ flexGrow: 1, width: '100%' }}>
              <span className="editor-detail">
                <RichTextEditor
                  readOnly={true}
                  editorState={toSlateState(advent_calendar_text)}
                />
              </span>
            </div>
          </div>

          <div className={'display-text-area'}>
            <span className={'pre-label'}>定型文</span>
            <span
              className={'pre-text pre-template-text'}
              dangerouslySetInnerHTML={{
                __html: advent_calendar_template?.replace(/\n/g, '<br />'),
              }}
            />
          </div>

          <div className={'display-text-area'}>
            <span className={'pre-label '}>キャンペーン表示期間</span>
            <span className={'pre-text'}>{`${format(
              display_start_date,
              'YYYY/M/D HH:mm'
            )} 〜 ${format(display_end_date, 'YYYY/M/D HH:mm')}`}</span>
          </div>

          <div className={'display-text-area long-label-area'}>
            <span className={'pre-label'}>
              アドベントカレンダー
              <br />
              実施期間
            </span>
            <div>
              <span className={'pre-text'}>{`${format(
                advent_calendar_start_date,
                'YYYY/M/D HH:mm'
              )} 〜 ${format(
                advent_calendar_end_date,
                'YYYY/M/D HH:mm'
              )}`}</span>
            </div>
          </div>

          <div className={'display-date-area'}>
            <span className={'pre-label'}>景品</span>
            <div className={'display-date-info-area'}>
              {advent_calendar_gifts?.map(gift => {
                return (
                  <div className={'display-date-info-elm'}>
                    <span className={'display-date-pre-label-data'}>
                      {format(gift?.advent_calendar_date, 'YYYY/M/D')}
                    </span>
                    <span className={'pre-text'}>{gift?.coupon_name}</span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className={'display-date-area'}>
            <span className={'pre-label'}>プッシュ通知</span>
            <div className={'display-date-info-area'} style={{ gap: 15 }}>
              <div className={'display-date-info-elm'}>
                <span
                  className={'display-date-pre-label'}
                  style={{ width: 140, fontSize: 14 }}
                >
                  キャンペーン表示開始
                </span>
                <span>{public_notice_flag === 1 ? 'する' : 'しない'}</span>
              </div>
              <div className={'display-date-info-elm'}>
                <span
                  className={'display-date-pre-label'}
                  style={{ width: 140, fontSize: 14 }}
                >
                  実施開始
                </span>
                <span>
                  {advent_calendar_notice_flag === 1 ? 'する' : 'しない'}
                </span>
              </div>
            </div>
          </div>

          <div className="button-container">
            <TcButton text={'閉じる'} theme="white" onClick={onBack} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AdventCalendarReferenceModal;
