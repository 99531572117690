import { Modal } from 'antd';
import Button from '../../tcButton';
import './style.scss';

/* 削除確認モーダル */

const DeleteModal = ({ modalVisible, onCancel, onOk, item, itemValue, gachaNoticeTextDisplay }) => {
  return (
    <Modal
      className={'tc-modal-delete'}
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      width={450}
      onCancel={onCancel}
    >
      <div className="modal-content">
        <div className="delete-title">削除確認</div>
        <div className="delete-info">
          {item ? (
            <>
              <div>
                {item}：{itemValue}
              </div>
              <div>を削除します</div>
              <div>よろしいですか？</div>
              {gachaNoticeTextDisplay ? (
                <div className="delete-tip" style={{fontWeight: "bold"}}>
                  ※実施中のガチャを削除するとアプリ上で<br />
                  ガチャを利用しているユーザーに影響を与える可能性があります
                </div>
              ) : (
                <div className="delete-tip">※削除した内容は復元できません</div>
              )}
            </>
          ) : (
            <>
              <div>選択した{itemValue}を削除します</div>
              <div>よろしいですか？</div>
            </>
          )}
        </div>
        <div className="delete-button-area">
          <Button text="削除する" theme="red" onClick={onOk} />
          <Button text="戻る" theme="white" onClick={onCancel} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
