import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import {
  fetchGachaCopy,
  fetchGachaCsvDownload,
  fetchGachaCsvFavoriteItem,
  fetchGachaCsvFavoriteItemCreate,
  fetchGachaDelete,
  fetchGachaDetail,
  fetchGachaList,
  fetchGachaReference,
  fetchGachaResult,
  gachaActions,
} from '../../slices/gachaSlice';
import {
  csvDownloadTypes,
  csvFavoriteItemTypes,
  gachaFavoriteItemOptions,
} from '../../constants/options';
import DeleteModal from '../../components/modal/deleteModal';
import PageTitle from '../../components/pageTitle';
import Icons from '../../constants/Icons';
import TableTitle from '../../components/tableTitle';
import TcButton from '../../components/tcButton';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import TcCheckbox from '../../components/tcCheckbox';
import { gachaColumns } from '../../constants/columns';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import CsvDownloadModal from '../../components/modal/csvDownloadModal';
import GachaModal from '../../components/gachaModal';
import GachaReferenceModal from '../../components/gachaModal/reference';
import GachaResultModal from '../../components/gachaModal/result';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';
import './style.scss';

const Gacha = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);
  const {
    searchParams,
    total,
    deleteGacha,
    favoriteItem,
    csvGacha,
    gachaList,
  } = useSelector(state => state.gacha);

  const { selectOptionList } = useSelector(state => state.name);

  const { accountInfo } = useSelector(state => state.account);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => {
      const {
        campaign_start_date,
        campaign_end_date,
        gacha_start_date,
        gacha_end_date,
        ...rest
      } = values;
      const params = {
        ...rest,
        campaign_start_date: dateFormat(campaign_start_date, 'YYYY/MM/DD'),
        campaign_end_date: dateFormat(campaign_end_date, 'YYYY/MM/DD'),
        gacha_start_date: dateFormat(gacha_start_date, 'YYYY/MM/DD'),
        gacha_end_date: dateFormat(gacha_end_date, 'YYYY/MM/DD'),
      };

      dispatch(gachaActions.saveSearchParams(params));
    },
  });

  useUpdateEffect(() => dispatch(fetchGachaList(searchParams)), [searchParams]);

  useEffect(() => {
    dispatch(
      fetchGachaCsvFavoriteItem({ screen_type: csvFavoriteItemTypes['gacha'] })
    ) && dispatch(gachaActions.saveSearchParams(null));
    return () => dispatch(gachaActions.clear());
  }, [dispatch]);

  const columns = [
    ...gachaColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => {
            dispatch(gachaActions.saveSearchParams({ sort }));
          }}
        />
      ),
      dataIndex: col.sortField,
      width: col.sortField === 'create_at' ? '100px' : 'auto',
      render: col.render,
    })),
    {
      title: '結果',
      align: 'center',
      width: '150px',
      render: current => (
        <div className="summary-area">
          <div className="summary-item-area">
            <TcButton
              text={'結果表示'}
              theme={'white'}
              icon={<Icons.IconResult className="btn-icon" />}
              onClick={() => dispatch(fetchGachaResult(current))}
            />
          </div>
          <TcButton
            text={'CSV出力'}
            theme={'white'}
            icon={<Icons.IconDownload className="btn-icon" />}
            onClick={() =>dispatch(gachaActions.openCsvDownloadModal(current))}
          />
        </div>
      ),
    },
    {
      title: '編集',
      align: 'right',
      width: '80px',
      render: current =>
        !current.deleted_flg ? (
          <Icons.IconEdit
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchGachaDetail(current))}
          />
        ) : (
          <Icons.IconEditGy
            className="tc-disabled-icon"
            height={'20px'}
            width={'20px'}
          />
        ),
    },
    {
      title: '複製',
      align: 'center',
      width: '80px',
      render: current => (
        <Icons.IconCopy
          className="tc-action-icon"
          height={'20px'}
          width={'20px'}
          onClick={() => dispatch(fetchGachaCopy(current))}
        />
      ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        !current.deleted_flg ? (
          <Icons.IconTrash
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => {
              dispatch(gachaActions.openDeleteModal(current));
            }}
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchGachaReference(current))}
          />
        ),
    },
  ];

  return (
    <div className="content-body management-page gacha" ref={parent}>
      <PageTitle
        title="新規登録・ガチャ一覧"
        subTitle={[
          '※トリガーが同一で実施期間が重複しているデータは登録することができません。',
        ]}
        subTitleClass={'subtitle-grey'}
        icon={<Icons.IconGatya />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1098}>
          <TextBox formik={formik} label="ガチャCD" fieldName="gacha_code" />
          <TextBox formik={formik} label="ガチャ名" fieldName="gacha_name" />
          <SelectBox
            formik={formik}
            label="トリガー"
            options={
              selectOptionList?.filter(s => s.field_name === 'トリガー')?.[0]
                ?.select_option ?? []
            }
            fieldName="trigger"
          />
          <DateTimeRangePicker
            formik={formik}
            label="キャンペーン表示開始日"
            fromFieldName="campaign_start_date"
            toFieldName="campaign_end_date"
          />
          <DateTimeRangePicker
            formik={formik}
            label="ガチャ実施開始日"
            fromFieldName="gacha_start_date"
            toFieldName="gacha_end_date"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={!!formik.values['deleted_flg']}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'deleted_flg',
                !!formik.values['deleted_flg'] ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(gachaActions.openEditModal(null))}
      />
      <TcTable
        columns={columns?.filter(colum =>
          accountInfo?.is_gacha_deletable_flag !== 1 ? colum.title !== '削除' : true
        )}
        dataSource={gachaList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(gachaActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />

      <CsvDownloadModal
        line={3}
        modalVisible={!!csvGacha}
        options={gachaFavoriteItemOptions}
        favoriteItem={favoriteItem}
        width={570}
        onFavoriteItem={checkedList => {
          const initial = gachaFavoriteItemOptions.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item.value]: '0',
              },
            }),
            {
              screen_type: csvFavoriteItemTypes['gacha'],
              favorite: {},
            }
          );

          const param = checkedList.reduce(
            (acc, item) => ({
              ...acc,
              favorite: {
                ...acc.favorite,
                [item]: '1',
              },
            }),
            initial
          );

          dispatch(fetchGachaCsvFavoriteItemCreate(param));
        }}
        onClose={() => dispatch(gachaActions.closeCsvDownloadModal())}
        onDownload={checkedList =>
          dispatch(
            fetchGachaCsvDownload({
              screen_type: csvDownloadTypes['gacha'],
              gacha_code: csvGacha?.gacha_code,
              columns: checkedList,
            })
          )
        }
      />
      <GachaModal />
      <GachaReferenceModal />
      <GachaResultModal />
      <DeleteModal
        modalVisible={!!deleteGacha}
        onCancel={() => dispatch(gachaActions.closeDeleteModal())}
        item={'ガチャ'}
        itemValue={deleteGacha?.gacha_code}
        onOk={() =>
          dispatch(
            fetchGachaDelete({
              gacha_code: deleteGacha?.gacha_code,
            })
          )
        }
        gachaNoticeTextDisplay={`※実施中のガチャを削除すると、${<br />}アプリ上でガチャを利用しているユーザーに影響を与える可能性があります`}
      />
    </div>
  );
};

export default Gacha;
