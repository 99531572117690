import { Upload } from 'antd';
import { getBase64 } from '../../utils/fnUtil';
import store from '../../slices/store';
import { globalActions } from '../../slices/globalSlice';

const BaseUpload = ({ children, onChange, videoUploadFlag, ...rest }) => {
  const accept = videoUploadFlag
    ? '.png,.gif,.jpg,.jpeg,.mp4,.mov'
    : '.png,.gif,.jpg,.jpeg';

  const formatList = () => [
    'image/png',
    'image/gif',
    'image/jpg',
    'image/jpeg',
    ...(videoUploadFlag ? ['video/mp4', 'video/quicktime'] : []),
  ];

  const showError = message => {
    store.dispatch(
      globalActions.showErrorModal({
        title: '読み取りエラー',
        message,
      })
    );
  };

  const beforeUpload = file => {
    const fileType = file.type;
    const fileSize = file.size;
    const isFormatValid = formatList().includes(fileType);

    if (fileType.startsWith('image/')) {
      if (!isFormatValid) {
        showError('png,gif,jpg,jpeg形式でアップロードしてください');
        return false;
      }

      const isLt5M = fileSize / 1024 / 1024 <= 5;

      if (!isLt5M) {
        showError('画像は5M以下でアップロードしてください');
        return false;
      }
    } else if (fileType.startsWith('video/')) {
      if (!isFormatValid) {
        showError('mp4,mov形式でアップロードしてください');
        return false;
      }

      const isLt1_5G = fileSize / 1024 / 1024 <= 20;

      if (!isLt1_5G) {
        showError('動画は20M以下でアップロードしてください');
        return false;
      }
    }

    getBase64(file, url => onChange(url));

    return false;
  };

  return (
    <Upload
      accept={accept}
      beforeUpload={beforeUpload}
      showUploadList={false}
      {...rest}
    >
      {children}
    </Upload>
  );
};

export default BaseUpload;
