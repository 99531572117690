import { Image } from 'antd';
import './style.scss';
import VideoImageTagElement from "../videoImageTagElement";
import React from "react";

const ImagesReference = ({ image_url, from, to, width, height }) => {
  const imageSize = { width, height };
  const getImagesJsx = (from, to) => {
    const result = image_url
      ?.filter(e => e)
      ?.map((image, i) => {
        if (i < from || to < i) return null;
        return image ? (
          <div key={image}>
            <div className="referenceImageBox" style={imageSize}>
              <VideoImageTagElement imageUrl={image} width={width} height={height} />
            </div>
            <div className="referenceImageText">
              {'画像' + (image_url.indexOf(image) + 1)}
            </div>
          </div>
        ) : null;
      })
      ?.filter(e => e);
    if (result.length !== 0) {
      const count = to - from + 1;
      for (let i = result.length; i < count; i++) {
        result.push(
          <div key={i}>
            <div className="referenceImageBox empty" style={imageSize}></div>
          </div>
        );
      }
    }
    return result;
  };
  return (
    <div className="referenceImageRow" style={{ marginBottom: 5 }}>
      {getImagesJsx(from, to)}
    </div>
  );
};

export default ImagesReference;
