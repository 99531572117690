import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import {
  fetchCouponList,
  fetchCouponTemplateList,
  fetchCouponDetail,
  fetchCouponReference,
  couponDelete,
  couponActions,
  fetchCouponCopy,
} from '../../slices/couponSlice';
import { couponColumns } from '../../constants/columns';
import { globalActions } from '../../slices/globalSlice';
import Icons from '../../constants/Icons';
import PageTitle from '../../components/pageTitle';
import TcButton from '../../components/tcButton';
import TcCheckbox from '../../components/tcCheckbox';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import CouponModal from '../../components/couponModal';
import CouponReferenceModal from '../../components/couponModal/reference';
import DeleteModal from '../../components/modal/deleteModal';
import TableTitle from '../../components/tableTitle';
import './style.scss';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';

const Coupon = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { searchParams, couponList, total, deleteModalVisible, deleteCoupon } =
    useSelector(state => state.coupon);

  const { selectOptionList } = useSelector(state => state.name);

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      const { from_usage_period, to_usage_period, ...rest } = values;
      const params = {
        ...rest,
        from_usage_period: dateFormat(from_usage_period, 'YYYY/MM/DD'),
        to_usage_period: dateFormat(to_usage_period, 'YYYY/MM/DD'),
      };

      dispatch(couponActions.saveSearchParams(params));
    },
  });

  useUpdateEffect(
    () => dispatch(fetchCouponList(searchParams)),
    [searchParams]
  );

  useEffect(() => {
    dispatch(couponActions.saveSearchParams(null));
    dispatch(fetchCouponTemplateList());
    return () => dispatch(couponActions.clear());
  }, [dispatch]);

  const columns = [
    ...couponColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => dispatch(couponActions.saveSearchParams({ sort }))}
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current => {
        return current.deleted_flg === '0' ? (
          <Icons.IconEdit
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchCouponDetail(current))}
          />
        ) : (
          <Icons.IconEditGy
            className="tc-disabled-icon"
            height={'20px'}
            width={'20px'}
          />
        );
      },
    },
    {
      title: '複製',
      align: 'center',
      width: '80px',
      render: current => (
        <Icons.IconCopy
          className="tc-action-icon"
          height={'20px'}
          width={'20px'}
          onClick={() => dispatch(fetchCouponCopy(current))}
        />
      ),
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current => {
        return current.deleted_flg === '0' ? (
          <Icons.IconTrash
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => {
              const errorConditions = [
                {
                  flag: current.stamp_linkage_flg,
                  message: '台紙に紐づくクーポンの削除はできません',
                },
                {
                  flag: current.lottery_linkage_flg,
                  message: '抽選に紐づくクーポンの削除はできません',
                },
                {
                  flag: current.advent_calendar_linkage_flg,
                  message: 'アドベントカレンダーに紐づくクーポンの削除はできません',
                },
              ];

              for (const condition of errorConditions) {
                if (condition.flag === '1') {
                  dispatch(
                    globalActions.showErrorModal({
                      title: '削除不可',
                      message: condition.message,
                    })
                  );
                  return;
                }
              }

              dispatch(couponActions.openDeleteModal(current));
            }}
          />
        ) : (
          <Icons.IconSearch
            className="tc-action-icon"
            height={'20px'}
            width={'20px'}
            onClick={() => dispatch(fetchCouponReference(current))}
          />
        );
      },
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="新規登録・クーポン一覧"
        icon={<Icons.IconCoupon className="coupon-icon" />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={1472}>
          <TextBox formik={formik} label="クーポンCD" fieldName="coupon_code" />
          <TextBox formik={formik} label="クーポン名" fieldName="coupon_name" />
          <SelectBox
            formik={formik}
            label="クーポン種別"
            fieldName="coupon_type"
            options={[
              { label: 'すべて', value: null },
              ...(selectOptionList?.filter(
                s => s.field_name === 'クーポン種別'
              )?.[0]?.select_option ?? []),
            ]}
          />
          <DateTimeRangePicker
            formik={formik}
            label="利用期間"
            fromFieldName="from_usage_period"
            toFieldName="to_usage_period"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={formik.values.deleted_flg === 1}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'deleted_flg',
                formik.values.deleted_flg === 1 ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickAdd={() => dispatch(couponActions.openEditModal(null))}
      />
      <TcTable
        columns={columns}
        dataSource={couponList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(couponActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <CouponModal />
      <CouponReferenceModal />
      <DeleteModal
        modalVisible={deleteModalVisible}
        onCancel={() => dispatch(couponActions.closeDeleteModal())}
        item={'クーポン'}
        itemValue={deleteCoupon?.coupon_code}
        onOk={() => dispatch(couponDelete(deleteCoupon?.coupon_code))}
      />
    </div>
  );
};

export default Coupon;
