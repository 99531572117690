import React, { memo } from 'react';
import { Image } from 'antd';
import Icons from '../../constants/Icons';
import BaseUpload from '../baseUpload';
import TcButton from '../tcButton';
import {
  handlerFormikFieldChange,
  isVideoOrImage,
  spliceArray
} from '../../utils/fnUtil';
import classNames from 'classnames';
import './style.scss';

const ImagesUploader = memo(
  ({ label, formik, fieldName, disabled = false, isSpanLabel }) => (
    <span className="cus-input-line input-images">
      {isSpanLabel ? (
        <span
          className={classNames('cus-pre-label', {
            'label-disabled': disabled,
          })}
        >
          {label}
        </span>
      ) : (
        <label className="cus-pre-label">{label}</label>
      )}

      <div className="images-container">
        {formik.values[fieldName].map((img, i) => (
          <div className="image-cell" key={i}>
            {img ? (
              <>
                <Icons.IconXCircleWh
                  className="delete-icon tc-action-icon"
                  onClick={e => {
                    handlerFormikFieldChange(
                      formik,
                      fieldName,
                      spliceArray(formik.values[fieldName], i, '', 1)
                    );
                    e.stopPropagation();
                  }}
                />
                {isVideoOrImage(img) === 'image' ? (
                  <Image src={img} width={'100%'} height={150} preview={false} />
                ) : (
                  <video src={img} width={'100%'} height={150} controls />
                )}
              </>
            ) : (
              <>
                <BaseUpload
                  disabled={disabled}
                  onChange={url =>
                    handlerFormikFieldChange(
                      formik,
                      fieldName,
                      spliceArray(formik.values[fieldName], i, url, 1)
                    )
                  }
                  onClick={() => formik.setFieldTouched(fieldName, true)}
                  videoUploadFlag={0}
                >
                  <TcButton
                    text="画像を追加"
                    style={{ width: 100 }}
                    theme="white"
                    disabled={disabled}
                  />
                </BaseUpload>
              </>
            )}
            <span
              className={classNames('image-label', {
                'label-disabled': disabled,
              })}
            >{`画像${i + 1}`}</span>
          </div>
        ))}
      </div>
    </span>
  )
);

export default ImagesUploader;
