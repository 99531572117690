import React, { useState, useEffect } from 'react';
import { Modal, Image, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import moment from 'moment';
import { globalActions } from '../../slices/globalSlice';
import {
  couponActions,
  couponCreate,
  couponUpdate,
  fetchCouponFileCheck, reserveCountCoupon,
} from '../../slices/couponSlice';
import {
  convertImageBtnText,
  convertImageFieldName,
  convertRichText, convertText,
  deleteLinkRichText,
  handlerFormikFieldChange,
  isVideoOrImage
} from '../../utils/fnUtil';
import BaseUpload from '../baseUpload';
import BaseFileUpload from '../../components/baseUpload/file';
import ModalTitle from '../modalTitle';
import TcCheckbox from '../../components/tcCheckbox';
import Button from '../../components/tcButton';
import DualListBox from '../../components/dualListBox';
import PrefecturesDualListBox from '../../components/dualListBox/prefectures';
import Icon from '@ant-design/icons';
import Icons from '../../constants/Icons';
import Yup from '../../utils/yupUtil';
import {
  prefectures,
  deliveryTargetOptions,
  genderOptions2,
} from '../../constants/options';
import classNames from 'classnames';
import './style.scss';
import TcButton from '../../components/tcButton';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import TextBox from '../util/textBox';
import DateTimeRangePicker from '../util/dateTimeRangePicker';
import CustomNumberPicker from '../util/customNumberPicker';
import SelectBox from '../util/selectBox';
import TcRadio from '../tcCheckbox/tcRadio'

/* クーポン登録モーダル */

const CouponModal = () => {
  const dispatch = useDispatch();

  const [selectCouponType, setSelectCouponType] = useState(null);
  const [availableFavoriteShops, setAvailableFavoriteShops] = useState([]);
  const [availablePrefectures, setAvailablePrefectures] = useState(prefectures);

  const {
    accountInfo,
    accountBrandList: brandList,
    accountShopList: shopList,
  } = useSelector(state => state.account);

  const { selectOptionList } = useSelector(state => state.name);

  const { add_user_data, new_contents_flag, add_article_data: { video_upload_flag } } = accountInfo;

  const {
    editModalVisible,
    currentCoupon,
    couponTemplateList,
    fileUploadStatus,
    couponReserveCount,
  } = useSelector(state => state.coupon);

  const isCreate = !currentCoupon;
  const isUpdate = !!currentCoupon?.coupon_code;

  const initialValues = {
    coupon_code: null,
    coupon_type: null,
    coupon_name: null,
    coupon_detail: '',
    rich_coupon_detail: toSlateState(currentCoupon?.rich_coupon_detail),
    image: null,
    use_date_from: null,
    use_hour_from: null,
    use_minute_from: null,
    use_date_to: null,
    use_hour_to: null,
    use_minute_to: null,
    from_send_date: null,
    from_send_hour: null,
    from_send_minute: null,
    to_send_date: null,
    to_send_hour: null,
    to_send_minute: null,
    valid_days_flag: '0',
    valid_days: null,
    barcode: null,
    barcode2: null,
    usage_count_flag: '0',
    usage_count: '0',
    template: null,
    fixed_phrase: '',
    delivery_target: '01',
    delivery_conditions_flag: '0',
    from_age: null,
    to_age: null,
    gender: '0',
    birth_month: null,
    prefectures_code: [],
    favorite_shops_code: [],
    target_list_type: '0',
    upload_file: null,
    upload_file_name: null,
    notice_flag: '0',
    day_count: null,
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object({
      coupon_name: Yup.string().nullable().required().max(50),
      image: Yup.string().nullable().required(),
      use_date_from: Yup.string()
        .nullable()
        .test(
          'use_date_from',
          '日時を選択してください',
          (value, testContext) =>
            testContext.parent.coupon_type !== '0' ||
            (value &&
              testContext.parent.use_hour_from &&
              testContext.parent.use_minute_from)
        ),
      use_date_to: Yup.string()
        .nullable()
        .test(
          'use_date_to',
          '日時を選択してください',
          (value, testContext) =>
            testContext.parent.coupon_type !== '0' ||
            (value &&
              testContext.parent.use_hour_to &&
              testContext.parent.use_minute_to)
        ),
      from_send_date: Yup.string()
        .nullable()
        .test(
          'from_send_date',
          '日時を選択してください',
          (value, testContext) =>
            testContext.parent.coupon_type !== '0' ||
            (value &&
              testContext.parent.from_send_hour &&
              testContext.parent.from_send_minute)
        ),
      to_send_date: Yup.string()
        .nullable()
        .test(
          'to_send_date',
          '日時を選択してください',
          (value, testContext) =>
            testContext.parent.coupon_type !== '0' ||
            (value &&
              testContext.parent.to_send_hour &&
              testContext.parent.to_send_minute)
        ),
      upload_file_name: Yup.string()
        .nullable()
        .test(
          'upload_file_name',
          'ファイルを選択してください',
          (value, testContext) =>
            testContext.parent.coupon_type !== '0' ||
            testContext.parent.delivery_target !== '04' ||
            value
        ),
      valid_days: Yup.string()
        .nullable()
        .test(
          'valid_days',
          '有効日数指定を入力してください',
          (value, testContext) =>
            testContext.parent.coupon_type === '0' || value
        )
        .matches(/^(([1-9][0-9]*)|0)$/, '形式が異なります'),
      usage_count: Yup.string()
        .nullable()
        .test(
          'usage_count',
          'クーポン利用回数を入力してください',
          (value, testContext) =>
            testContext.parent.usage_count_flag === '0' || value
        ),
      day_count: Yup.string()
        .nullable()
        .matches(/^(([1-9][0-9]*)|0)$/, '形式が異なります'),
    }),
    onSubmit: async values => {
      const couponType = values.coupon_type;

      const richText = deleteLinkRichText(values.rich_coupon_detail);

      const rich_coupon_detail = convertRichText(new_contents_flag, richText);
      const coupon_detail = convertText(new_contents_flag, richText, values.coupon_detail);

      if (couponType === '0') {
        // 通常クーポン
        const fromUsagePeriod = moment(values.use_date_from)
          .set('h', Number(values.use_hour_from))
          .set('m', Number(values.use_minute_from));
        const toUsagePeriod = moment(values.use_date_to)
          .set('h', Number(values.use_hour_to))
          .set('m', Number(values.use_minute_to));

        // 利用日前後チェック
        if (!fromUsagePeriod.isBefore(toUsagePeriod)) {
          dispatch(
            globalActions.showErrorModal({
              title: 'エラー',
              message: '利用開始日は利用終了日より前の日付を入力してください',
            })
          );
          return;
        }

        const fromSendDate = moment(values.from_send_date)
          .set('h', Number(values.from_send_hour))
          .set('m', Number(values.from_send_minute));
        const toSendDate = moment(values.to_send_date)
          .set('h', Number(values.to_send_hour))
          .set('m', Number(values.to_send_minute));

        // 発行日前後チェック
        if (!fromSendDate.isBefore(toSendDate)) {
          dispatch(
            globalActions.showErrorModal({
              title: 'エラー',
              message: '発行開始日は発行終了日より前の日付を入力してください',
            })
          );
          return;
        }

        // 発行開始日/利用開始日前後チェック
        if (!fromSendDate.isSameOrBefore(fromUsagePeriod)) {
          dispatch(
            globalActions.showErrorModal({
              title: 'エラー',
              message:
                '発行開始日は利用開始日と同じか前の日付を入力してください',
            })
          );
          return;
        }

        // 発行終了日/利用終了日前後チェック
        if (!toSendDate.isBefore(toUsagePeriod)) {
          dispatch(
            globalActions.showErrorModal({
              title: 'エラー',
              message: '発行終了日は利用終了日より前の日付を入力してください',
            })
          );
          return;
        }

        if (!isUpdate) {
          // 過去日チェック
          if (fromUsagePeriod.isBefore(moment())) {
            dispatch(
              globalActions.showErrorModal({
                title: 'エラー',
                message: '利用開始日に過去日を設定することはできません',
              })
            );
            return;
          }

          // 過去日チェック
          if (fromSendDate.isBefore(moment())) {
            dispatch(
              globalActions.showErrorModal({
                title: 'エラー',
                message: '発行開始日に過去日を設定することはできません',
              })
            );
            return;
          }
        }

        const params = {
          ...values,
          from_usage_period: fromUsagePeriod.format('YYYY-MM-DD HH:mm:ss'),
          to_usage_period: toUsagePeriod.format('YYYY-MM-DD HH:mm:ss'),
          from_send_date: fromSendDate.format('YYYY-MM-DD HH:mm:ss'),
          to_send_date: toSendDate.format('YYYY-MM-DD HH:mm:ss'),
          prefectures_code: prefectures_code?.map(e => e.value),
          favorite_shops_code: favorite_shops_code?.map(e => e.shop_code),
          operator_code: accountInfo.staff_code,
          rich_coupon_detail,
          coupon_detail,
          target_list_type: conversionFIleType(target_list_type),
        };
        saveCoupon(params);
      } else {
        // スタンプ用, アンケート用、ガチャ用、抽選用クーポン、友達紹介
        const params = {
          ...values,
          operator_code: accountInfo.staff_code,
          rich_coupon_detail,
          coupon_detail,
        };
        saveCoupon(params);
      }
    },
  });

  const saveCoupon = params => {
    if (isUpdate) {
      dispatch(couponUpdate(params));
    } else {
      dispatch(couponCreate(params));
    }
  };

  const setDisplayValues = () => {
    const format = (date, format) =>
      date ? moment(date)?.format(format) : null;
    currentCoupon
      ? formik.setValues({
          ...currentCoupon,
          use_date_from: format(currentCoupon?.from_usage_period, 'YYYY/M/D'),
          use_hour_from: format(currentCoupon?.from_usage_period, 'HH'),
          use_minute_from: format(currentCoupon?.from_usage_period, 'mm'),
          use_date_to: format(currentCoupon?.to_usage_period, 'YYYY/M/D'),
          use_hour_to: format(currentCoupon?.to_usage_period, 'HH'),
          use_minute_to: format(currentCoupon?.to_usage_period, 'mm'),
          from_send_date: format(currentCoupon?.from_send_date, 'YYYY/M/D'),
          from_send_hour: format(currentCoupon?.from_send_date, 'HH'),
          from_send_minute: format(currentCoupon?.from_send_date, 'mm'),
          to_send_date: format(currentCoupon?.to_send_date, 'YYYY/M/D'),
          to_send_hour: format(currentCoupon?.to_send_date, 'HH'),
          to_send_minute: format(currentCoupon?.to_send_date, 'mm'),
          rich_coupon_detail: toSlateState(currentCoupon?.rich_coupon_detail),
          template: couponTemplateList?.filter(e => e.template_text === currentCoupon?.fixed_phrase)?.[0]?.template_code,
          target_list_type: setFileTypeValue(currentCoupon)
        })
      : formik.setValues({ ...initialValues });
  };

  useUpdateEffect(() => {
    setDisplayValues();
  }, [currentCoupon]);

  const {
    values: {
      coupon_type,
      coupon_detail,
      image,
      valid_days,
      usage_count_flag,
      usage_count,
      fixed_phrase,
      delivery_target,
      delivery_conditions_flag,
      from_age,
      to_age,
      gender,
      birth_month,
      prefectures_code,
      favorite_shops_code,
      notice_flag,
      target_list_type,
      upload_file_name,
      day_count,
    },
    setFieldValue,
  } = formik;

  useUpdateEffect(() => {
    if (editModalVisible) {
      setSelectCouponType(null);
      setAvailableFavoriteShops([]);
      setAvailablePrefectures(prefectures);
      setDisplayValues();
    } else {
      formik.resetForm();
      formik.validateForm();
    }
  }, [editModalVisible]);

  useEffect(() => {
    if (currentCoupon) {
      if (!isUpdate) {
        setSelectCouponType(currentCoupon.coupon_type);
      }
      const selectPrefectures = currentCoupon?.prefectures_code?.map(e => ({
        value: e,
        label: prefectures?.find(p => p?.value === e)?.label,
      }));
      setAvailablePrefectures(
        prefectures?.filter(
          e => !currentCoupon?.prefectures_code?.includes(e.value)
        ) || []
      );
      handlerFormikFieldChange(formik, 'prefectures_code', selectPrefectures);

      const selectFavoriteShops =
        currentCoupon?.favorite_shops_code?.map(e => ({
          shop_code: e,
          shop_name:
            shopList.filter(s => e === s.shop_code)?.[0]?.shop_name || e,
        })) || [];
      setAvailableFavoriteShops([]);
      handlerFormikFieldChange(
        formik,
        'favorite_shops_code',
        selectFavoriteShops
      );

      formik.validateForm();
    }
  }, [currentCoupon]);

  const onBack = () => {
    dispatch(couponActions.closeEditModal());
    formik.resetForm();
  };

  const reserveCount = () => {
    if (delivery_target === "04") {
      dispatch(reserveCountCoupon({
        cond_flag: "2",
        file_type: conversionFIleType(target_list_type),
        upload_file: formik.values.upload_file,
        add_user_data_params: formik.values?.add_user_data_params ?? null
      }))
    } else {
      dispatch(reserveCountCoupon({
        ...formik?.values,
        cond_flag: formik?.values?.delivery_conditions_flag,
        prefectures_code: prefectures_code?.map(p => p.value),
        favorite_shops_code: favorite_shops_code?.map(p => p.value),
      }))
    }
  };

  const conversionFIleType = fileType => {

    let file_type = fileType;

    if (file_type === "0") {
      return file_type
    } else if (file_type === "1") {
      return file_type
    } else {
      return "99"
    }
  }

  const setFileTypeValue = currentCoupon => {

    if (currentCoupon) {
      if (currentCoupon.target_list_type) {
        const fileType = currentCoupon?.target_list_type;

        if (fileType === "99" && currentCoupon?.add_user_data_params) {
          return Object.keys(currentCoupon?.add_user_data_params)?.[0];
        } else {
          return fileType
        }
      }
    } else {
      return null;
    }
  }

  const createCouponType = type => {
    return isUpdate ? (
      <div style={{ marginRight: 20 }}>
        {`${
          selectOptionList?.filter(s => s.field_name === 'クーポン種別')?.[0]
            ?.select_option?.find(v => v.value === coupon_type)?.label ??
          selectOptionList?.filter(s => s.field_name === 'クーポン種別')?.[0]
            ?.select_option?.[0].label
        }クーポン`}
      </div>
    ) : (
      <div style={{ display: 'flex' }} key={type}>
        <Select
          name={'select_coupon_type'}
          className={classNames('select_coupon_type', {
            'select-disabled': coupon_type,
          })}
          value={type}
          placeholder={'クーポン種別を選択してください'}
          onChange={setSelectCouponType}
          disabled={coupon_type}
          suffixIcon={
            coupon_type ? (
              <Icons.IconChevronDownGy className="ant-select-suffix select-icon" />
            ) : (
              <Icons.IconChevronDown className="ant-select-suffix select-icon" />
            )
          }
        >
          {selectOptionList?.filter(s => s.field_name === 'クーポン種別')?.[0]
            ?.select_option?.map(v => (
            <Select.Option
              value={v.value}
            >{`${v.label}クーポン`}</Select.Option>
          ))}
        </Select>
        <Button
          text="反映"
          style={{
            margin: 0,
            marginLeft: 5,
            marginRight: 20,
            width: 50,
          }}
          disabled={coupon_type}
          onClick={() => {
            setFieldValue('use_date_from', null);
            setFieldValue('use_hour_from', null);
            setFieldValue('use_minute_from', null);
            setFieldValue('use_date_to', null);
            setFieldValue('use_hour_to', null);
            setFieldValue('use_minute_to', null);
            setFieldValue('from_send_date', null);
            setFieldValue('from_send_hour', null);
            setFieldValue('from_send_minute', null);
            setFieldValue('to_send_date', null);
            setFieldValue('to_send_hour', null);
            setFieldValue('to_send_minute', null);
            setFieldValue('usage_count_flag', '0');
            setFieldValue('usage_count', '0');
            setFieldValue('delivery_target', '01');
            setFieldValue('delivery_conditions_flag', '0');
            setFieldValue('from_age', null);
            setFieldValue('to_age', null);
            setFieldValue('gender', '0');
            setFieldValue('birth_month', null);
            setFieldValue('prefectures_code', []);
            setFieldValue('favorite_shops_code', []);
            setFieldValue('target_list_type', '0');
            setFieldValue('upload_file', null);
            setFieldValue('upload_file_name', null);
            setFieldValue('notice_flag', '0');
            if (type === '0') {
              setFieldValue('valid_days_flag', '0');
              setFieldValue('valid_days', null);
            } else {
              setFieldValue('valid_days_flag', '1');
              setFieldValue('valid_days', '1');
            }
            handlerFormikFieldChange(formik, 'coupon_type', type);
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      className={'coupon-modal tc-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={
            isCreate
              ? 'クーポン登録'
              : isUpdate
              ? 'クーポン編集'
              : 'クーポン登録(複製)'
          }
          tip={'*は必須項目です'}
          rightElement={createCouponType(selectCouponType)}
        />
        <div className="input-area">
          <TextBox
            formik={formik}
            preLabel="クーポン名"
            fieldName="coupon_name"
            width={'100%'}
            disabled={!coupon_type}
            isRequired
          />
          <div
            className="cus-input-line input-textarea text-area"
            style={{ height: 'unset' }}
          >
            <label
              className={classNames('text-required', {
                'label-disabled': !coupon_type,
              })}
              style={{ flexShrink: '0' }}
            >
              クーポン説明
            </label>

            {new_contents_flag !== 0 ? (
              <div style={{ width: '100%' }}>
                <RichTextEditor
                  extraUpload={true}
                  editorState={formik.values?.rich_coupon_detail}
                  readOnly={!coupon_type}
                  error={
                    formik.touched.rich_coupon_detail &&
                    formik.errors.rich_coupon_detail
                  }
                  onChange={state =>
                    formik.setFieldValue('rich_coupon_detail', state)
                  }
                  setTouched={() =>
                    !formik.touched.rich_coupon_detail &&
                    formik.setFieldTouched('rich_coupon_detail', true)
                  }
                  placeholder={'クーポン説明を入力してください'}
                  disabled={!coupon_type}
                />
              </div>
            ) : (
              <textarea
                placeholder="クーポン説明を入力してください"
                spellCheck={false}
                autoComplete={'false'}
                value={coupon_detail}
                onChange={e =>
                  handlerFormikFieldChange(
                    formik,
                    'coupon_detail',
                    e.target.value
                  )
                }
                disabled={!coupon_type}
              />
            )}
          </div>

          <div className="image-area">
            <span
              className={classNames('pre-label', {
                'label-required': !!coupon_type,
                'label-disabled': !coupon_type,
                'label-required-disabled': !coupon_type,
              })}
            >
              {convertImageFieldName(video_upload_flag, "画像")}
            </span>
            <div className="image-select">
              {image && (
                <Icon
                  component={Icons.IconXCircleWh}
                  className="close-icon"
                  onClick={e => {
                    handlerFormikFieldChange(formik, 'image', null);
                    e.stopPropagation();
                  }}
                  disabled={!coupon_type}
                />
              )}
              {image ? (
                <>
                  {isVideoOrImage(image) === 'image' ? (
                    <Image
                      src={image}
                      width={150}
                      height={200}
                      preview={false}
                      disabled={!coupon_type}
                    />
                  ) : (
                    <video src={image} width={150} height={200} controls />
                  )}
                </>
              ) : (
                <>
                  <BaseUpload
                    onChange={url =>
                      handlerFormikFieldChange(formik, 'image', url)
                    }
                    onClick={() => formik.setFieldTouched('image', true)}
                    disabled={!coupon_type}
                    videoUploadFlag={video_upload_flag}
                  >
                    <Button
                      text={convertImageBtnText(video_upload_flag, "画像を追加")}
                      theme="white"
                      disabled={!coupon_type}
                    />
                  </BaseUpload>
                </>
              )}
            </div>
          </div>
          {coupon_type === '0' && (
            <>
              <DateTimeRangePicker
                formik={formik}
                preLabel={'利用期間'}
                fromFieldName={'use_date_from'}
                fromHourFieldName={'use_hour_from'}
                fromMinuteFieldName={'use_minute_from'}
                toFieldName={'use_date_to'}
                toHourFieldName={'use_hour_to'}
                toMinuteFieldName={'use_minute_to'}
                fromUneditable={isUpdate}
                isRequired
                width={'100%'}
              />
              <DateTimeRangePicker
                formik={formik}
                preLabel={'発行期間'}
                fromFieldName={'from_send_date'}
                fromHourFieldName={'from_send_hour'}
                fromMinuteFieldName={'from_send_minute'}
                toFieldName={'to_send_date'}
                toHourFieldName={'to_send_hour'}
                toMinuteFieldName={'to_send_minute'}
                fromUneditable={isUpdate}
                toUneditable={isUpdate}
                isRequired
                width={'100%'}
              />
            </>
          )}
          {coupon_type !== '0' && (
            <div className="check-area">
              <span
                className={classNames('pre-label', {
                  'label-required': !!coupon_type,
                  'label-disabled': !coupon_type,
                  'label-required-disabled': !coupon_type,
                })}
                style={{ whiteSpace: 'pre-wrap' }}
              >{`クーポン${'\n'}有効日数指定`}</span>
              {isUpdate ? (
                <>
                  {`クーポン発行からn日間の有効日数を指定する ${valid_days}日`}
                </>
              ) : (
                <div className="input-line">
                  <span className={!!coupon_type ? '' : 'label-disabled'}>
                    クーポン発行からn日間の有効日数を指定する
                  </span>
                  <TextBox
                    formik={formik}
                    fieldName="valid_days"
                    placeholder="0"
                    disabled={!coupon_type}
                    width={150}
                    hasLabel={false}
                    suffix={'日'}
                    style={{ marginLeft: 5 }}
                  />
                  <span className="tc-select-error">
                    {formik.touched['valid_days'] &&
                      formik.errors['valid_days']}
                  </span>
                </div>
              )}
            </div>
          )}
          <TextBox
            formik={formik}
            preLabel={selectOptionList?.filter(s => s.field_name === '回収バーコード')?.[0]
              ?.select_option?.find(v => v.value === "0")?.label}
            fieldName="barcode"
            width={'100%'}
            disabled={!coupon_type}
          />
          <TextBox
            formik={formik}
            preLabel={selectOptionList?.filter(s => s.field_name === '回収バーコード')?.[0]
              ?.select_option?.find(v => v.value === "1")?.label}
            fieldName="barcode2"
            width={'100%'}
            disabled={!coupon_type}
          />
          <div className="check-area">
            <span
              className={classNames('pre-label', {
                'label-required': !!coupon_type,
                'label-disabled': !coupon_type,
                'label-required-disabled': !coupon_type,
              })}
            >
              クーポン利用回数
            </span>
            {isUpdate ? (
              <>{usage_count_flag === '0' ? '無制限' : `${usage_count}回`}</>
            ) : (
              <div className="use-count-container">
                <TcCheckbox
                  label={'無制限'}
                  checked={usage_count_flag === '0'}
                  onChange={() => {
                    handlerFormikFieldChange(formik, 'usage_count_flag', '0');
                    handlerFormikFieldChange(formik, 'usage_count', '0');
                  }}
                  disabled={!coupon_type}
                  isRadio={true}
                />
                <TcCheckbox
                  label={'回数制限'}
                  checked={usage_count_flag === '1'}
                  onChange={() => {
                    handlerFormikFieldChange(formik, 'usage_count_flag', '1');
                    handlerFormikFieldChange(formik, 'usage_count', '1');
                  }}
                  wrapperStyle={{ marginLeft: '30px' }}
                  disabled={!coupon_type}
                  isRadio={true}
                />
                <CustomNumberPicker
                  formik={formik}
                  fieldName="usage_count"
                  disabled={!coupon_type || usage_count_flag === '0'}
                  suffix={'回'}
                  from={1}
                  to={100}
                  placeholder={'0'}
                  width={150}
                  style={{ marginLeft: '10px' }}
                  hasLabel={false}
                />
                <span className="tc-select-error">
                  {formik.touched['usage_count'] &&
                    formik.errors['usage_count']}
                </span>
              </div>
            )}
          </div>
          <SelectBox
            formik={formik}
            preLabel="定型文"
            fieldName="template"
            options={couponTemplateList?.map(e => ({
              value: e.template_code,
              label: e.template_title,
            }))}
            width={400}
            extraOnChange={(_, v) =>
              handlerFormikFieldChange(
                formik,
                'fixed_phrase',
                couponTemplateList?.filter(e => e.template_code === v)?.[0]?.template_text
              )
            }
            disabled={!coupon_type}
          />
          <div
            className="cus-input-line input-textarea"
            style={{ marginTop: '5px' }}
          >
            <label />
            <textarea
              spellCheck={false}
              autoComplete={'false'}
              value={fixed_phrase}
              onChange={e =>
                handlerFormikFieldChange(formik, 'fixed_phrase', e.target.value)
              }
              disabled={!coupon_type}
            />
          </div>
          {coupon_type !== '0' ? (
            <></>
          ) : (
            <>
              <div className="check-area">
                <span
                  className={classNames('pre-label', 'label-required', {
                    'label-disabled': !coupon_type,
                  })}
                >
                  配信対象
                </span>
                {isUpdate ? (
                  <>{deliveryTargetOptions[delivery_target]}</>
                ) : (
                  <>
                    <TcCheckbox
                      label={'全ユーザ(配信後のダウンロード含む)'}
                      checked={delivery_target === '01'}
                      onChange={() =>
                        handlerFormikFieldChange(
                          formik,
                          'delivery_target',
                          '01'
                        )
                      }
                      disabled={!coupon_type}
                      isRadio={true}
                      className={"coupon-delivery-target-first"}
                    />
                    <TcCheckbox
                      label={'配信前のダウンロードユーザーのみ'}
                      checked={delivery_target === '02'}
                      onChange={() =>
                        handlerFormikFieldChange(
                          formik,
                          'delivery_target',
                          '02'
                        )
                      }
                      wrapperStyle={{ marginLeft: '10px' }}
                      disabled={!coupon_type}
                      isRadio={true}
                    />
                  </>
                )}
              </div>
              {!isUpdate && (
                <div className="check-area" style={{ marginTop: '15px' }}>
                  <span className={'pre-label'}></span>
                  <TcCheckbox
                    label={'配信後のダウンロードユーザーのみ'}
                    checked={delivery_target === '03'}
                    onChange={() => {
                      handlerFormikFieldChange(formik, 'delivery_target', '03')
                      handlerFormikFieldChange(
                        formik,
                        'delivery_conditions_flag',
                        '0'
                      )
                    }}
                    disabled={!coupon_type}
                    isRadio={true}
                    className={"coupon-delivery-target-first"}
                  />
                  <TcCheckbox
                    label={'IDアップロード'}
                    checked={delivery_target === '04'}
                    onChange={() => {
                      handlerFormikFieldChange(formik, 'delivery_target', '04');
                      handlerFormikFieldChange(
                        formik,
                        'delivery_conditions_flag',
                        '0'
                      );
                      handlerFormikFieldChange(formik, 'target_list_type', '0');
                    }}
                    wrapperStyle={{ marginLeft: '10px' }}
                    disabled={!coupon_type}
                    isRadio={true}
                  />
                </div>
              )}
              {delivery_target === '03' ? (
                  <>
                    {!isUpdate ? (
                      <TextBox
                        formik={formik}
                        preLabel="クーポン受取期間"
                        fieldName="day_count"
                        disabled={!coupon_type}
                        placeholder="0"
                        width={312}
                        suffix={'日'}
                      />
                    ) : (
                      <div className="coupon-modal-coupon-day-count-area">
                        <label>クーポン受取期間</label>
                        <span>{`${day_count ?? "-"}　日`}</span>
                      </div>
                    ) }
                  </>
              ) : delivery_target === '04' ? (
                <>
                  <div className="check-area" style={{alignItems: "flex-start"}}>
                    <span
                      className={'pre-label label-required'}
                      style={{ whiteSpace: 'pre-wrap' }}
                    >{`IDリスト`}</span>
                    <div className="file-type-area">
                      <div className="file-type-label">ファイル区分</div>
                      <div className={"file-type-radio-btn-area"}>
                        <TcCheckbox
                          label={'端末ID'}
                          checked={target_list_type === '0'}
                          onChange={() => {
                            handlerFormikFieldChange(formik, 'upload_file', null);
                            handlerFormikFieldChange(
                              formik,
                              'upload_file_name',
                              null
                            );
                            dispatch(couponActions.clearFileUploadStatus());
                            handlerFormikFieldChange(
                              formik,
                              'target_list_type',
                              '0'
                            );
                          }}
                          disabled={!coupon_type}
                          isRadio={true}
                        />
                        <TcCheckbox
                          label={'会員番号'}
                          checked={target_list_type === '1'}
                          onChange={() => {
                            handlerFormikFieldChange(formik, 'upload_file', null);
                            handlerFormikFieldChange(
                              formik,
                              'upload_file_name',
                              null
                            );
                            dispatch(couponActions.clearFileUploadStatus());
                            handlerFormikFieldChange(
                              formik,
                              'target_list_type',
                              '1'
                            );
                          }}
                          wrapperStyle={{ marginLeft: '30px' }}
                          disabled={!coupon_type}
                          isRadio={true}
                        />
                        {add_user_data && add_user_data?.length > 0 ? add_user_data.filter(data => data?.filter_type === "0")?.map(data => (
                          <TcCheckbox
                            isRadio={true}
                            checked={target_list_type === `${data?.field_name}`}
                            formik={formik}
                            label={`${data?.name}`}
                            onChange={() => {
                              handlerFormikFieldChange(formik, 'upload_file', null);
                              handlerFormikFieldChange(formik, 'upload_file_name', null);
                              dispatch(couponActions.clearFileUploadStatus());
                              handlerFormikFieldChange(
                                formik,
                                'target_list_type',
                                `${data?.field_name}`
                              );
                              const fieldName = { field_name: `${data?.field_name}` }; // 例: dataの内容

                              handlerFormikFieldChange(
                                formik,
                                `add_user_data_params`,
                                {
                                  ...(data?.field_name ? { [fieldName.field_name]: "1" } : {})
                                }
                              );
                            }}
                          />
                        )) : (<></>)}
                      </div>
                    </div>
                  </div>
                  <div className="file-area">
                    <BaseFileUpload
                      onChange={v => {
                        handlerFormikFieldChange(formik, 'upload_file', v.file);
                        handlerFormikFieldChange(
                          formik,
                          'upload_file_name',
                          v.file.name
                        );
                        dispatch(
                          fetchCouponFileCheck({
                            operator_code: accountInfo.staff_code,
                            target_list_type: conversionFIleType(target_list_type),
                            upload_file: v.file,
                            add_user_data_params: formik.values?.add_user_data_params ?? null
                          })
                        );
                      }}
                      disabled={!coupon_type}
                      maxLine={90000}
                    >
                      <Button
                        text="ファイル選択"
                        theme="white"
                        style={{ margin: 0, marginLeft: 130, marginTop: 5 }}
                        icon={<Icons.IconClip />}
                        disabled={!coupon_type}
                      />
                    </BaseFileUpload>
                    <div
                      style={{
                        marginLeft: 10,
                        maxWidth: 300,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      ファイル名：{upload_file_name ? upload_file_name : '-'}
                    </div>
                    {fileUploadStatus && (
                      <div style={{ marginLeft: 20 }}>
                        ファイル取込成功件数：{fileUploadStatus?.success_count}{' '}
                        / {fileUploadStatus?.all_count}
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="check-area">
                    <span
                      className={classNames('pre-label', 'label-required', {
                        'label-disabled': !coupon_type,
                      })}
                    >
                      配信条件
                    </span>
                    {isUpdate ? (
                      <>
                        {delivery_conditions_flag === '0'
                          ? '条件なし'
                          : '条件あり'}
                      </>
                    ) : (
                      <>
                        <TcCheckbox
                          label={'条件なし'}
                          checked={delivery_conditions_flag === '0'}
                          onChange={() =>
                            handlerFormikFieldChange(
                              formik,
                              'delivery_conditions_flag',
                              '0'
                            )
                          }
                          disabled={!coupon_type}
                          isRadio={true}
                        />
                        <TcCheckbox
                          label={'条件あり'}
                          checked={delivery_conditions_flag === '1'}
                          onChange={() =>
                            handlerFormikFieldChange(
                              formik,
                              'delivery_conditions_flag',
                              '1'
                            )
                          }
                          wrapperStyle={{ marginLeft: '30px' }}
                          disabled={!coupon_type}
                          isRadio={true}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
              {delivery_target !== '04' && delivery_conditions_flag === '1' ? (
                <>
                  <span className="cus-input-line input-age">
                    <CustomNumberPicker
                      preLabel="年齢"
                      formik={formik}
                      fieldName="from_age"
                      from={1}
                      to={100}
                      placeholder={'00'}
                      width={302}
                      suffix={isUpdate ? '' : '〜'}
                      uneditable={isUpdate}
                      displayValue={
                        !from_age && !to_age
                          ? '指定なし'
                          : `${from_age || '指定なし'} ~ ${
                              to_age || '指定なし'
                            }`
                      }
                    />
                    {!isUpdate && (
                      <CustomNumberPicker
                        formik={formik}
                        fieldName="to_age"
                        from={1}
                        to={100}
                        width={150}
                        placeholder="00"
                        style={{ marginLeft: 10 }}
                      />
                    )}
                  </span>

                  <div className="check-area">
                    <span className={'pre-label'}>性別</span>
                    {isUpdate ? (
                      <>{genderOptions2[gender]?.label}</>
                    ) : (
                      <TcRadio
                        options={genderOptions2}
                        formik={formik}
                        label={''}
                        width={230}
                        fieldName={'gender'}
                        cancelable={false}
                        isRadio={true}
                      />
                    )}
                  </div>
                  <CustomNumberPicker
                    preLabel="誕生月"
                    formik={formik}
                    fieldName="birth_month"
                    from={1}
                    to={12}
                    placeholder={'00'}
                    width={302}
                    suffix={isUpdate ? '' : '月'}
                    uneditable={isUpdate}
                    disabled={!coupon_type}
                    displayValue={birth_month ? `${birth_month}月` : '指定なし'}
                  />
                  <div className="check-area">
                    <span className={'pre-label'}>都道府県</span>
                    <PrefecturesDualListBox
                      selectBoxId="prefectures_select_box"
                      selectPrefectures={prefectures_code}
                      setSelectPrefectures={v =>
                        handlerFormikFieldChange(formik, 'prefectures_code', v)
                      }
                      availablePrefectures={availablePrefectures}
                      setAvailablePrefectures={setAvailablePrefectures}
                      disabled={!coupon_type || isUpdate}
                    />
                  </div>
                  <div className="check-area">
                    <span className={'pre-label'}>お気に入り店舗</span>
                    <DualListBox
                      selectBoxId="favorite_shop_select_box"
                      brandList={brandList}
                      shopList={shopList}
                      selectShops={favorite_shops_code}
                      setSelectShops={v =>
                        handlerFormikFieldChange(
                          formik,
                          'favorite_shops_code',
                          v
                        )
                      }
                      availableShops={availableFavoriteShops}
                      setAvailableShops={setAvailableFavoriteShops}
                      disabled={!coupon_type || isUpdate}
                    />
                  </div>
                  {(add_user_data ?? []).map(a => {
                    switch (a.filter_type) {
                      case '0':
                        return <></>
                      case '1':
                      case '2':
                        return (
                          <SelectBox
                            formik={formik}
                            preLabel={a.name}
                            fieldName={`add_user_data_params.${a.field_name}`}
                            mode={a.filter_type === '2' ? 'multiple' : null}
                            options={(a.filter_list ?? []).map(l => ({
                              label: l.list_name,
                              value: l.list_code,
                            }))}
                          />
                        );
                      case '3':
                        return (
                          <span className="cus-input-line input-age">
                            <CustomNumberPicker
                              preLabel={a.name}
                              formik={formik}
                              fieldName={`add_user_data_params.${a.field_name}_start`}
                              placeholder={'0'}
                              width={302}
                              suffix={'〜'}
                              from={a.filter_range?.start ?? 0}
                              to={a.filter_range?.end ?? 0}
                            />
                            <CustomNumberPicker
                              formik={formik}
                              fieldName={`add_user_data_params.${a.field_name}_end`}
                              width={150}
                              placeholder="0"
                              style={{ marginLeft: 10 }}
                              from={a.filter_range?.start ?? 0}
                              to={a.filter_range?.end ?? 0}
                            />
                          </span>
                        );
                      case '4':
                        return (
                          <DateTimeRangePicker
                            formik={formik}
                            preLabel={a.name}
                            fromFieldName={`add_user_data_params.${a.field_name}_start`}
                            toFieldName={`add_user_data_params.${a.field_name}_end`}
                            maxWidth={150}
                            width={462}
                          />
                        );
                      default:
                        return null;
                    }
                  })}
                </>
              ) : (
                <></>
              )}
              {!isUpdate && (
                <div
                  className="reserve-count-area"
                  style={delivery_target === '03' ? { marginTop: '24px' } : {}}
                >
                  <div className="reserve-count-label">想定配信人数</div>
                  <div className="reserve-count-value">{`${delivery_target === '03' ? 0 : couponReserveCount?.toLocaleString()}　人`}</div>
                  {delivery_target === '03' ? (
                    <></>
                  ) : (
                    <TcButton
                      text="集計"
                      theme="white"
                      onClick={reserveCount}
                    />
                  )}
                </div>
              )}
              <div className="check-area">
                <span
                  className={classNames('pre-label', 'label-required', {
                    'label-disabled': !coupon_type,
                  })}
                >
                  プッシュ配信通知
                </span>
                {isUpdate ? (
                  <>{notice_flag === '0' ? 'しない' : 'する'}</>
                ) : (
                  <>
                    <TcCheckbox
                      label={'する'}
                      checked={notice_flag === '1'}
                      onChange={() =>
                        handlerFormikFieldChange(formik, 'notice_flag', '1')
                      }
                      disabled={!coupon_type}
                      isRadio={true}
                    />
                    <TcCheckbox
                      label={'しない'}
                      checked={notice_flag === '0'}
                      onChange={() =>
                        handlerFormikFieldChange(formik, 'notice_flag', '0')
                      }
                      wrapperStyle={{ marginLeft: '30px' }}
                      disabled={!coupon_type}
                      isRadio={true}
                    />
                  </>
                )}
              </div>
            </>
          )}
          <div className="button-container">
            <Button text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={isUpdate ? '更新' : '登録'}
              onClick={formik.handleSubmit}
              disabled={
                !coupon_type ||
                !formik.isValid ||
                (delivery_target === '04' &&
                  fileUploadStatus?.success_count === 0)
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CouponModal;
