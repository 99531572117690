import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import ModalTitle from '../modalTitle';
import { gachaActions } from '../../slices/gachaSlice';
import {convertImageFieldName, getOptionLabel} from '../../utils/fnUtil';
import {
  flagOptions,
  winningOptions,
} from '../../constants/options';
import moment from 'moment';
import TcButton from '../tcButton';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import './style.scss';
import VideoImageTagElement from "../videoImageTagElement";
import ImagesReference from "../imagesReference";

const GachaReferenceModal = () => {
  const dispatch = useDispatch();
  const { referenceGacha, couponList } = useSelector(state => state.gacha);

  const { selectOptionList } = useSelector(state => state.name);

  const {
    accountInfo: { new_contents_flag, add_article_data: { video_upload_flag } },
  } = useSelector(state => state.account);

  const initialValues = {
    gacha_code: null,
    gacha_name: null,
    trigger: null,
    consecutive_startup_days: null,
    banner_image_url: null,
    gacha_content: null,
    image_url: Array(10).fill(''),
    fixed_phrase: null,
    campaign_start_date: null,
    campaign_start_hour: null,
    campaign_start_minute: null,
    campaign_end_date: null,
    campaign_end_hour: null,
    campaign_end_minute: null,
    gacha_start_date: null,
    gacha_start_hour: null,
    gacha_start_minute: null,
    gacha_end_date: null,
    gacha_end_hour: null,
    gacha_end_minute: null,
    campaign_notice_flag: '1',
    gacha_notice_flag: '1',
    lottery_method: '0',
    focused_rank: null,
    gift_list: [
      {
        rank: '1',
        coupon_code: null,
        stamp_count: null,
        max_winner_count: null,
        winner_probability: null,
        is_checked: true,
        is_coupon: true,
      },
      {
        rank: '2',
        coupon_code: null,
        stamp_count: null,
        max_winner_count: null,
        winner_probability: null,
        is_checked: false,
        is_coupon: true,
      },
      {
        rank: '3',
        coupon_code: null,
        stamp_count: null,
        max_winner_count: null,
        winner_probability: null,
        is_checked: false,
        is_coupon: true,
      },
    ],
  };

  const {
    gacha_code,
    gacha_name,
    trigger,
    consecutive_startup_days,
    banner_image_url,
    gacha_content,
    gacha_rich_content,
    image_url,
    fixed_phrase,
    campaign_start_date,
    campaign_end_date,
    gacha_start_date,
    gacha_end_date,
    campaign_notice_flag,
    gacha_notice_flag,
    lottery_method,
    gift_list,
    stamp_rally_code,
    stamp_rally_name,
  } = referenceGacha || initialValues;

  const onBack = () => dispatch(gachaActions.closeReferenceModal());

  const dateToString = date => (
    <div className="font-sm">
      <span>{moment(date)?.format('YYYY/M/D HH:mm')}</span>
    </div>
  );

  const getLoseProbabiliry = () =>
    gift_list.reduce((acc, gift) => acc - (gift.winner ?? 0), 100);

  return (
    <Modal
      className={'gacha-reference-modal tc-modal'}
      open={!!referenceGacha}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title="ガチャ参照" />
        <div className="input-area">
          <div className="reference-area">
            <span className="pre-label">ガチャCD</span>
            <span className="font-sm">{gacha_code}</span>
          </div>
          <div className="reference-area">
            <span className="pre-label">トリガー</span>
            <span className="font-sm">
              {getOptionLabel(
                selectOptionList?.filter(
                  s => s.field_name === 'トリガー'
                )?.[0]?.select_option ?? [],
                trigger
              )}
            </span>
          </div>
          {!!Number(trigger) && (
            <div className="reference-area">
              <span className="pre-label">連続起動日数</span>
              <span className="font-sm">{consecutive_startup_days}</span>
              <span className="space-left-small">日</span>
            </div>
          )}
          <div className="image-area">
            <span className="pre-label">{convertImageFieldName(video_upload_flag, "バナー画像")}</span>
            <div className="image-select">
              {!!banner_image_url && (
                <VideoImageTagElement
                  imageUrl={banner_image_url}
                  height={150}
                />
              )}
            </div>
          </div>
          <div className="reference-area">
            <span className="pre-label">ガチャ名</span>
            <span className="font-sm">{gacha_name}</span>
          </div>
          <div className="reference-area" style={{ alignItems: 'flex-start' }}>
            <span className="pre-label">ガチャ説明</span>
            {new_contents_flag !== 0 ? (
              <div className="text-area" style={{ flexGrow: 1 }}>
                <span className="editor-detail">
                  <RichTextEditor
                    readOnly={true}
                    editorState={toSlateState(gacha_rich_content)}
                  />
                </span>
              </div>
            ) : (
              <span style={{ whiteSpace: 'pre-wrap' }}>{gacha_content}</span>
            )}
          </div>
          <div className="reference-image-area">
            <label className="text-required">画像</label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '80%',
              }}
            >
              <ImagesReference
                image_url={image_url}
                from={0}
                to={4}
                width={150}
                height={150}
              />
              <ImagesReference
                image_url={image_url}
                from={5}
                to={9}
                width={150}
                height={150}
              />
            </div>
          </div>
          <div className="reference-area">
            <span className="pre-label">定型文</span>
            <span className="font-sm">
              {!!fixed_phrase ? fixed_phrase : '-'}
            </span>
          </div>
          <div className="reference-area">
            <span className="pre-label">キャンペーン表示期間</span>
            {dateToString(campaign_start_date)}
            <span className="space">〜</span>
            {dateToString(campaign_end_date)}
          </div>
          <div className="reference-area">
            <span className="pre-label">ガチャ実施期間</span>
            {dateToString(gacha_start_date)}
            <span className="space">〜</span>
            {dateToString(gacha_end_date)}
          </div>
          <span className="push-area">
            <span className="pre-label push-label">プッシュ通知</span>
            <div className="push-list">
              <div className="radio-area">
                <span className="pre-label">キャンペーン表示開始</span>
                <span className="font-sm">
                  {getOptionLabel(flagOptions, campaign_notice_flag)}
                </span>
              </div>
              <div className="radio-area end">
                <span className="pre-label">ガチャ実施開始</span>
                <span className="font-sm">
                  {getOptionLabel(flagOptions, gacha_notice_flag)}
                </span>
              </div>
            </div>
          </span>
          <div className="reference-area type">
            <span className="pre-label ">当選形式</span>
            <span className="font-sm" style={{ fontSize: '12px' }}>
              {getOptionLabel(winningOptions, lottery_method)}
            </span>
          </div>

          <div className="active-stamp-area">
            <div className="label">スタンプカード</div>
            <div className="item-area">
              <div className="item">
                {`${stamp_rally_code ?? '-'}：${stamp_rally_name ?? '-'}`}
              </div>
            </div>
          </div>

          <span className="gift-area">
            <span className="pre-label gift-label">景品</span>
            <div className="gift-list">
              {gift_list.map((v, i) => (
                <>
                  <div className="bold">{`${v.rank}等`}</div>
                  {!!v?.coupon_code ? (
                    <div className="gift-input-area">
                      <div className="pre-label">クーポン</div>
                      {!!v?.coupon_code && !!couponList?.length ? (
                        couponList
                          .filter(coupon => coupon.value === v.coupon_code)
                          .map(coupon => (
                            <div className="font-sm">
                              <span>{coupon.value}</span>
                              <span className="space-left">{coupon.label}</span>
                            </div>
                          ))
                      ) : (
                        <span className="font-sm">-</span>
                      )}
                    </div>
                  ) : (
                    <div className="gift-input-area">
                      <span className="pre-label">スタンプ数</span>
                      <span className="font-sm">
                        {v?.stamp_count
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </span>
                      <span className="space-left-small">個</span>
                    </div>
                  )}
                  <div className={i < gift_list.length - 1 && 'not-end'}>
                    {!!Number(lottery_method) ? (
                      <div className="gift-input-area">
                        <span className="pre-label">確率</span>
                        <span className="font-sm">{v.winner_probability}</span>
                        <span className="space-left-small">%</span>
                      </div>
                    ) : (
                      <div className="gift-input-area">
                        <span className="pre-label">本数</span>
                        <span className="font-sm">
                          {v?.max_winner_count
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </span>
                        <span className="space-left-small">本</span>
                      </div>
                    )}
                  </div>
                </>
              ))}
              {!!Number(lottery_method) && (
                <div className="gift-input-area">
                  <span className="pre-label bold">はずれ</span>
                  <span className="font-sm">{getLoseProbabiliry()}</span>
                  <span className="space-left-small">%</span>
                </div>
              )}
            </div>
          </span>
          <div className="button-container">
            <TcButton text="閉じる" onClick={onBack} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GachaReferenceModal;
