import axios from '../utils/axiosUtil';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import { fetchGeopushList } from './geopushSlice';
import moment from 'moment';
import { downloadFile } from '../utils/fnUtil';

const initialSearchParams = {
  title: '',
  message: '',
  from_create_at: null,
  to_create_at: null,
  show_delete_flag: 0,
  count: 10,
  sort: 'document_id_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  notificationList: [],
  total: 0,
  notificationModalVisible: false,
  notificationDetail: null,
  notificationSettingModalVisible: false,
  notificationReserveDetail: null,
  referenceModalVisible: false,
  referenceDocument: null,
  fileUploadStatus: null,
  geopush: false,
  mode: '',
  scenarioType: [],
  notificationReserveCount: 0,
};

export const fetchNotificationList = createAsyncThunk(
  'fetchNotificationList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('/notification/document/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchNotificationDetail = createAsyncThunk(
  'fetchNotificationDetail',
  async params => await axios.get('/notification/document/detail', { params })
);

export const fetchNotificationReference = createAsyncThunk(
  'fetchNotificationReference',
  async params => await axios.get('/notification/document/detail', { params })
);

export const fetchScenarioType = createAsyncThunk(
  'fetchScenarioType',
  async params => await axios.get('/notification/scenario/list', { params })
);

export const fetchGeopushNotification = createAsyncThunk(
  'fetchGeopushNotification',
  async params => await axios.get('/notification/document/detail', { params })
);

export const fetchNotificationCopy = createAsyncThunk(
  'fetchNotificationCopy',
  async params => await axios.get('/notification/document/detail', { params })
);

export const fetchGeopushNotificationCopy = createAsyncThunk(
  'fetchGeopushNotificationCopy',
  async params => await axios.get('/notification/document/detail', { params })
);

export const fetchNotificationReserveFileCheck = createAsyncThunk(
  'fetchNotificationReserveFileCheck',
  async params =>
    await axios.post(
      '/notification/reserve/filecheck',
      createFormData(params),
      {
        headers: { 'content-type': 'multipart/form-data' },
        isFileUpload: true,
      }
    )
);

export const updateNotification = createAsyncThunk(
  'updateNotification',
  async (params, { dispatch, getState }) => {
    await axios.post(
      `/notification/document/${params.isCreate ? 'create' : 'update'}`,
      params
    );

    dispatch(fetchNotificationList(getState().notification.searchParams));

    if (getState().notification.geopush)
      dispatch(fetchGeopushList(getState().geopush.searchParams));

    dispatch(
      globalActions.showSingleModal(
        `${params.isCreate ? '登録' : '更新'}が完了しました`
      )
    );
  }
);

export const fetchNotificationReserveDetail = createAsyncThunk(
  'fetchNotificationReserveDetail',
  async params => {
    const detail = await axios.get('/notification/reserve/detail', { params });

    return {
      ...(detail.item ?? {}),
      document_id: params.document_id,
    };
  }
);

export const updateNotificationReserve = createAsyncThunk(
  'updateNotificationReserve',
  async (params, { dispatch, getState }) => {
    await axios.post(
      `/notification/reserve/${params.isCreate ? 'create' : 'update'}`,
      createFormData(params),
      {
        headers: { 'content-type': 'multipart/form-data' },
        isFileUpload: true,
      }
    );

    dispatch(fetchNotificationList(getState().notification.searchParams));

    dispatch(
      globalActions.showSingleModal(
        `${params.isCreate ? '登録' : '更新'}が完了しました`
      )
    );
  }
);

export const cancelNotificationReserve = createAsyncThunk(
  'cancelNotificationReserve',
  async (params, { dispatch, getState }) => {
    await axios.post('/notification/reserve/cancel', params);

    dispatch(fetchNotificationList(getState().notification.searchParams));

    dispatch(globalActions.showSingleModal('配信設定をキャンセルしました'));
  }
);

export const createFormData = params => {
  const formData = new FormData();
  formData.append('upload_file', params.upload_file);
  formData.append(
    'jsonValue',
    new Blob([JSON.stringify(params)], { type: 'application/json' })
  );
  return formData;
};

export const deleteNotification = createAsyncThunk(
  'deleteNotification',
  async (params, { dispatch, getState }) => {
    await axios.post('/notification/document/delete', params);

    dispatch(fetchNotificationList(getState().notification.searchParams));
  }
);

export const deleteGeopushNotification = createAsyncThunk(
  'deleteGeopushNotification',
  async (params, { dispatch, getState }) => {
    await axios.post('/notification/document/delete', params);

    dispatch(fetchGeopushList(getState().geopush.searchParams));
  }
);

export const reserveCountNotification = createAsyncThunk(
  'reserveCountNotification',
  async (params) =>
    await axios.post('/notification/reserve/count', createFormData(params), {
      headers: { 'content-type': 'multipart/form-data' },
      isFileUpload: true,
    })
);

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams.offset = payload;
    },
    setNotificationModalVisible: (state, { payload }) => {
      state.notificationModalVisible = payload;
    },
    setGeopush: state => {
      state.geopush = true;
      state.mode = 'add';
    },
    resetNotificationModal: state => {
      state.notificationModalVisible = false;
      state.notificationDetail = null;
      state.notificationSettingModalVisible = false;
      state.notificationReserveDetail = null;
      state.fileUploadStatus = null;
      state.geopush = false;
      state.notificationReserveCount = 0;
      state.mode = '';
    },
    closeReferenceModal: state => ({
      ...state,
      referenceModalVisible: false,
      referenceDocument: null,
    }),
    clearFileUploadStatus: state => ({
      ...state,
      fileUploadStatus: null,
    }),
    reset: () => initialState,
  },
  extraReducers: {
    [fetchNotificationList.fulfilled]: (state, { payload }) => {
      state.notificationList = payload.item ?? [];
      state.total = payload.search_count;
      state.geopush = false;
      state.mode = '';
    },
    [fetchNotificationDetail.fulfilled]: (state, { payload }) => {
      state.notificationDetail =
        {
          ...payload.item,
          richText: payload.item?.rich_text
            ? payload.item.rich_text
              .split('-----separator-----')
              .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        } ?? {};
      state.notificationModalVisible = true;
    },
    [fetchGeopushNotification.fulfilled]: (state, { payload }) => {
      state.notificationDetail =
        {
          ...payload.item,
          richText: payload.item?.rich_text
            ? payload.item.rich_text
              .split('-----separator-----')
              .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        } ?? {};
      state.geopush = true;
      state.mode = 'edit';
      console.log(`geopush edit`)
      state.notificationModalVisible = true;
    },
    [fetchNotificationCopy.fulfilled]: (state, { payload }) => {
      state.notificationDetail =
        {
          ...payload.item,
          richText: payload.item?.rich_text
            ? payload.item.rich_text
              .split('-----separator-----')
              .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        } ?? {};
      state.notificationModalVisible = true;
      state.geopush = false;
      state.mode = 'copy';
    },
    [fetchGeopushNotificationCopy.fulfilled]: (state, { payload }) => {
      state.notificationDetail =
        {
          ...payload.item,
          richText: payload.item?.rich_text
            ? payload.item.rich_text
              .split('-----separator-----')
              .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        } ?? {};
      state.notificationModalVisible = true;
      state.geopush = true;
      state.mode = 'copy';
    },
    [fetchNotificationReference.fulfilled]: (state, { payload }) => {
      state.referenceDocument = payload.item ?? {};
      state.referenceModalVisible = true;
      state.mode = '';
    },
    [updateNotification.fulfilled]: state => {
      state.notificationModalVisible = false;
      state.mode = 'edit';
      state.notificationDetail = null;
    },
    [fetchNotificationReserveDetail.fulfilled]: (state, { payload }) => {
      state.notificationReserveDetail = {
        document_id: payload.document_id,
        reserve_id: payload.reserve_id,
        send_type: payload.send_type ?? '1',
        repeat_type: payload.repeat_type ?? '0',
        send_date: payload.send_plan_date,
        send_time_hh: payload.send_reserve_time
          ? moment(payload.send_reserve_time, 'HH:mm').format('HH')
          : null,
        send_time_mm: payload.send_reserve_time
          ? moment(payload.send_reserve_time, 'HH:mm').format('mm')
          : null,
        repeat_send_time_hh: payload.repeat_send_time
          ? moment(payload.repeat_send_time, 'HH:mm').format('HH')
          : null,
        repeat_send_time_mm: payload.repeat_send_time
          ? moment(payload.repeat_send_time, 'HH:mm').format('mm')
          : null,
        repeat_period: payload.repeat_period,
        timing:
          payload.repeat_period === 'day'
            ? payload.timing?.day
            : payload.repeat_period === 'week'
              ? payload.timing?.week
              : payload.repeat_period === 'month'
                ? payload.timing?.month?.month_day
                  ? 0
                  : 1
                : null,
        repeat_send_time: payload.repeat_send_time,
        repeat_start_date: payload.repeat_start_date ?? '',
        repeat_end_date: payload.repeat_end_date ?? '',
        cond_flag: payload.cond_flag ?? '0',
        from_age: payload.from_age,
        to_age: payload.to_age,
        gender: payload.gender,
        birth_month: payload.birth_month,
        prefectures_list: payload.prefectures_list,
        shop_list: payload.shop_list,
        file_type: payload.list_type ?? "0",
        upload_file_name: payload.upload_file_name,
        isCreate: !payload.reserve_id,
        add_user_data_params: payload.add_user_data_params,
        scenario_type: payload.scenario_type,
        scenario_day: payload.scenario_day,
        scenario_day_type: payload.scenario_day_type,
      };
      state.notificationSettingModalVisible = true;
      state.notificationReserveCount = 0;
    },
    [fetchNotificationReserveFileCheck.fulfilled]: (state, { payload }) => {
      state.fileUploadStatus = {
        all_count: payload.item?.all_count || 0,
        success_count: payload.item?.success_count || 0,
        faild_count: payload.item?.faild_count || 0,
      };
      if (payload.item?.faild_count > 0) {
        const fileType =
          payload.params.target_list_type === '0' ? '端末ID' : 'ユーザーCD';
        downloadFile(
          payload.item.faild_list.join('\r\n') + '\r\n',
          `取込失敗_${fileType}_${moment().format('YYYYMMDDHHmmss')}.txt`
        );
      }
    },
    [updateNotificationReserve.fulfilled]: state => {
      state.notificationReserveDetail = null;
      state.notificationSettingModalVisible = false;
      state.notificationReserveCount = 0;
    },
    [cancelNotificationReserve.fulfilled]: state => {
      state.notificationReserveDetail = null;
      state.notificationSettingModalVisible = false;
      state.notificationReserveCount = 0;
    },
    [fetchScenarioType.fulfilled]: (state, { payload }) => {
      const scenarioTypeList = payload.item ?? [];
      state.scenarioType = scenarioTypeList?.sort((a, b) => a.display_order - b.display_order)?.map(item => ({
        label: item?.scenario_name,
        value: item?.scenario_code,
      }));
    },
    [reserveCountNotification.fulfilled]: (state, { payload }) => {
      state.notificationReserveCount = payload.item?.delivery_count ?? 0;
    },
  },
});

export const notificationActions = notificationSlice.actions;
export default notificationSlice.reducer;
