import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Yup from '../../utils/yupUtil';
import {
  enqueteActions,
  fetchEnqueteCouponList,
  fetchEnqueteCreate,
  fetchEnqueteUpdate,
  fetchEnqueteTemplateList,
} from '../../slices/enqueteSlice';
import { fetchCategoryList } from '../../slices/categorySlice';
import { CATEGORY_TYPE } from '../../constants/enums';
import { useFormik } from 'formik';
import Modal from 'antd/lib/modal/Modal';
import ModalTitle from '../modalTitle';
import TcButton from '../tcButton';
import {
  convertRichText, convertText,
  deleteLinkRichText,
  getOptionLabel,
  handlerFormikFieldChange
} from '../../utils/fnUtil';
import moment from 'moment';
import { globalActions } from '../../slices/globalSlice';
import TcRadio from '../tcCheckbox/tcRadio';
import { flagOptions } from '../../constants/options';
import './style.scss';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import QuestionList from './question';
import TextBox from '../util/textBox';
import SelectBox from '../util/selectBox';
import DateTimeRangePicker from '../util/dateTimeRangePicker';
import VideoImageElement from "../videoImageElement";

const EnqueteModal = () => {
  const dispatch = useDispatch();

  const format = (date, format) => (date ? moment(date)?.format(format) : null);

  const { editModalVisible, currentEnquete, templateList, couponList } =
    useSelector(state => state.enquete);

  const { categoryList } = useSelector(state => state.category);

  const {
    accountInfo: { new_contents_flag, add_article_data: { video_upload_flag }, },
  } = useSelector(state => state.account);

  const initialQuestion = {
    image_url: null,
    question_name: null,
    required_flag: '1',
    answer_type: '01',
    answer_list: [{ answer_text: null }],
    answer_result_display_flag: '1',
    graph_type: '00',
    summary_flag: {
      summary_sex_flag: '1',
      summary_age_flag: '0',
      summary_address_flag: '0',
      summary_favorite_shop_flag: '0',
    },
  };

  const initialValues = {
    category_code: [],
    enquete_code: null,
    enquete_name: '',
    enquete_contents: null,
    rich_enquete_contents: toSlateState(currentEnquete?.rich_enquete_contents),
    banner_image_url: null,
    public_start_date: null,
    public_start_hour: null,
    public_start_minute: null,
    public_end_date: null,
    public_end_hour: null,
    public_end_minute: null,
    fixed_phrase: null,
    coupon_code: null,
    notice_flag: '1',
    question_list: [initialQuestion],
  };

  const isCreate = !currentEnquete;
  const isUpdate = !!currentEnquete?.enquete_code;

  const yupObject = {
    enquete_name: Yup.string().required().max(50),
    public_start_date: Yup.string()
      .nullable()
      .test(
        'public_start_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.public_start_hour &&
          testContext.parent.public_start_minute
      ),
    public_end_date: Yup.string()
      .nullable()
      .test(
        'public_end_date',
        '日時を選択してください',
        (value, testContext) =>
          value &&
          testContext.parent.public_end_hour &&
          testContext.parent.public_end_minute
      ),
    question_list: Yup.array().of(
      Yup.object({
        question_name: Yup.string().nullable().required(),
        answer_list: Yup.array()
          .of(Yup.object({ answer_text: Yup.string().nullable() }))
          .when('answer_type', (answer_type, schema) =>
            Number(answer_type) > 2
              ? schema.strip()
              : schema.test('answer_list', '必須', value =>
                  value.some(v => !!v.answer_text)
                )
          ),
        summary_flag: Yup.object().when('answer_type', (answer_type, schema) =>
          Number(answer_type) > 2
            ? schema.strip()
            : schema.test('summary_flag', '集計処理を選択してください', value =>
                Object.values(value).some(v => !!Number(v))
              )
        ),
      })
    ),
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object(yupObject),
    onSubmit: async values => {
      const {
        public_start_date,
        public_start_hour,
        public_start_minute,
        public_end_date,
        public_end_hour,
        public_end_minute,
        enquete_code,
        enquete_contents,
        rich_enquete_contents,
        ...rest
      } = values;
      const startPeriod = moment(public_start_date)
        .set('h', Number(public_start_hour))
        .set('m', Number(public_start_minute));
      const endPeriod = moment(public_end_date)
        .set('h', Number(public_end_hour))
        .set('m', Number(public_end_minute));

      // アンケート期間前後チェック
      if (!startPeriod.isBefore(endPeriod)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message:
              'アンケート開始日はアンケート終了日より前の日付を入力してください',
          })
        );
        return;
      }

      const richText = deleteLinkRichText(values?.rich_enquete_contents);

      const params = {
        ...rest,
        question_list: rest.question_list.map((question, quesionIndex) =>
          Number(question.answer_type) > 2
            ? {
                question_code: `${quesionIndex + 1}`,
                image_url: question.image_url,
                question_name: question.question_name,
                required_flag: question.required_flag,
                answer_type: question.answer_type,
              }
            : {
                ...question,
                question_code: `${quesionIndex + 1}`,
                answer_list: question.answer_list
                  .filter(v => !!v.answer_text)
                  .map((answer, answerIndex) => ({
                    answer_code: `${answerIndex + 1}`,
                    answer_text: answer.answer_text,
                  })),
              }
        ),
        public_start_date: startPeriod.format('YYYY-MM-DD HH:mm:ss'),
        public_end_date: endPeriod.format('YYYY-MM-DD HH:mm:ss'),
        rich_enquete_contents: convertRichText(new_contents_flag, richText),
        enquete_contents: convertText(new_contents_flag, richText, enquete_contents),
      };

      return isUpdate
        ? dispatch(
            fetchEnqueteUpdate({
              enquete_code: enquete_code,
              ...params,
            })
          )
        : dispatch(fetchEnqueteCreate(params));
    },
  });

  const setValues = () => {
    formik.setValues({
      ...currentEnquete,
      question_list: !!currentEnquete?.question_list?.length
        ? [
            ...currentEnquete?.question_list?.map(v =>
              Number(v.answer_type) > 2
                ? {
                    ...initialQuestion,
                    image_url: v.image_url,
                    question_name: v.question_name,
                    required_flag: v.required_flag,
                    answer_type: v.answer_type,
                  }
                : { ...v }
            ),
          ]
        : [initialQuestion],
      public_start_date: format(currentEnquete?.public_start_date, 'YYYY/M/D'),
      public_start_hour: format(currentEnquete?.public_start_date, 'HH'),
      public_start_minute: format(currentEnquete?.public_start_date, 'mm'),
      public_end_date: format(currentEnquete?.public_end_date, 'YYYY/M/D'),
      public_end_hour: format(currentEnquete?.public_end_date, 'HH'),
      public_end_minute: format(currentEnquete?.public_end_date, 'mm'),
      rich_enquete_contents: toSlateState(
        currentEnquete?.rich_enquete_contents
      ),
      template: templateList?.filter(
        e => e?.template_text === currentEnquete?.fixed_phrase
      )?.[0]?.template_code,
    });
  };

  useEffect(
    () =>
      editModalVisible
        ? currentEnquete
          ? setValues()
          : formik.validateForm()
        : formik.resetForm(),
    [editModalVisible, templateList]
  );

  useEffect(
    () =>
      editModalVisible &&
      dispatch(fetchCategoryList({ category_type: CATEGORY_TYPE.ENQUETE })) &&
      dispatch(fetchEnqueteTemplateList()) &&
      dispatch(fetchEnqueteCouponList()),
    [editModalVisible]
  );

  const onBack = () => dispatch(enqueteActions.closeEditModal());

  const { banner_image_url, notice_flag } = formik.values;

  return (
    <Modal
      className={'enquete-modal tc-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={
            isCreate
              ? 'アンケート登録'
              : isUpdate
              ? 'アンケート編集'
              : 'アンケート登録(複製)'
          }
          tip={'*は必須項目です'}
        />
        <div className="input-area">
          {isUpdate && (
            <div className="code-area">
              <span className={'pre-label'}>アンケートCD</span>
              {formik.values['enquete_code']}
            </div>
          )}
          <SelectBox
            preLabel="アンケートカテゴリ"
            formik={formik}
            fieldName="category_code"
            options={categoryList.map(d => ({
              label: d.category_name,
              value: d.category_code,
            }))}
            mode="multiple"
            width={500}
          />
          <TextBox
            formik={formik}
            preLabel="アンケート名"
            fieldName="enquete_name"
            width={'100%'}
            isRequired
          />
          <span
            className="cus-input-line input-textarea"
            style={new_contents_flag !== 0 ? { height: 'unset' } : {}}
          >
            <label className="cus-pre-label enquete-content">
              アンケート説明
            </label>
            {new_contents_flag !== 0 ? (
              <div style={{ width: '100%' }}>
                <RichTextEditor
                  editorState={formik.values?.rich_enquete_contents}
                  error={
                    formik.touched.rich_enquete_contents &&
                    formik.errors.rich_enquete_contents
                  }
                  onChange={state =>
                    formik.setFieldValue('rich_enquete_contents', state)
                  }
                  setTouched={() =>
                    !formik.touched.rich_enquete_contents &&
                    formik.setFieldTouched('rich_enquete_contents', true)
                  }
                  placeholder={'アンケート説明を入力してください'}
                  disabled={false}
                />
              </div>
            ) : (
              <textarea
                placeholder="アンケート説明を入力してください"
                spellCheck={false}
                autoComplete={'false'}
                value={formik.values['enquete_contents']}
                onChange={e =>
                  handlerFormikFieldChange(
                    formik,
                    'enquete_contents',
                    e.target.value
                  )
                }
              />
            )}
          </span>
          <VideoImageElement
            fieldName={"画像"}
            btnText={"画像を追加"}
            imageUrl={banner_image_url}
            onChange={url => handlerFormikFieldChange(formik, 'banner_image_url', url)}
            uploadAction={() => formik.setFieldTouched('banner_image_url', true)}
            deleteAction={() =>  handlerFormikFieldChange(formik, 'banner_image_url', null)}
            width={150}
            height={150}
            videoUploadFlag={video_upload_flag}
          />
          <SelectBox
            formik={formik}
            preLabel="定型文"
            fieldName="template"
            placeholder="テンプレートを選択"
            options={templateList}
            width={400}
            extraOnChange={(_, v) =>
              handlerFormikFieldChange(
                formik,
                'fixed_phrase',
                templateList.find(e => e.value === v)?.template_text
              )
            }
          />
          <span className="cus-input-line input-textarea">
            <label className="cus-pre-label" />
            <textarea
              placeholder=""
              spellCheck={false}
              autoComplete={'false'}
              value={formik.values.fixed_phrase}
              onChange={e =>
                handlerFormikFieldChange(formik, 'fixed_phrase', e.target.value)
              }
            />
          </span>
          <DateTimeRangePicker
            formik={formik}
            preLabel={'アンケート期間'}
            fromFieldName={'public_start_date'}
            fromHourFieldName={'public_start_hour'}
            fromMinuteFieldName={'public_start_minute'}
            toFieldName={'public_end_date'}
            toHourFieldName={'public_end_hour'}
            toMinuteFieldName={'public_end_minute'}
            fromUneditable={isUpdate}
            isRequired
            width={'100%'}
          />
          <SelectBox
            formik={formik}
            preLabel="クーポン"
            fieldName="coupon_code"
            placeholder="クーポンを選択してください"
            uneditable={isUpdate}
            displayValue={
              !!currentEnquete?.coupon_code && !!couponList?.length
                ? couponList
                    .filter(
                      coupon => coupon.value === currentEnquete.coupon_code
                    )
                    .map(coupon => (
                      <>
                        <span>{coupon.value}</span>
                        <span className="space-left">{coupon.label}</span>
                      </>
                    ))
                : '-'
            }
            options={couponList}
            width={'100%'}
          />
          {isUpdate ? (
            <span className="cus-input-line push-area">
              <span className="pre-label label-required">プッシュ通知配信</span>
              {getOptionLabel(flagOptions, notice_flag)}
            </span>
          ) : (
            <span className="cus-input-line input-checkbox">
              <TcRadio
                formik={formik}
                label={'プッシュ通知配信'}
                isRadio={true}
                fieldName="notice_flag"
                width={150}
                required={true}
                options={flagOptions}
                onChange={v =>
                  handlerFormikFieldChange(formik, 'notice_flag', v)
                }
              />
            </span>
          )}

          <QuestionList
            formik={formik}
            initial={initialQuestion}
            isUpdate={isUpdate}
          />

          <div className="button-container">
            <TcButton text="戻る" theme="white" onClick={onBack} />
            <TcButton
              text={isUpdate ? '更新' : '登録'}
              onClick={formik.handleSubmit}
              disabled={!formik.isValid}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EnqueteModal;
