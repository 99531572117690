import React from 'react';
import { isVideoOrImage } from '../../utils/fnUtil';
import { Image } from 'antd';

const VideoImageTagElement = ({ imageUrl, width, height }) => {
  const imageWidth = width ? width : 150;
  const imageHeight = height ? height : 200;

  return isVideoOrImage(imageUrl) === 'video' ? (
    <video src={imageUrl} width={imageWidth} height={imageHeight} controls />
  ) : (
    <Image
      src={imageUrl}
      width={imageWidth}
      height={imageHeight}
      preview={false}
    />
  );
};

export default VideoImageTagElement;
