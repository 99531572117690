import React, { useEffect } from 'react';
import { useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Modal } from 'antd';
import { globalActions } from '../../slices/globalSlice';
import {
  stampRallyActions,
  fetchStampRallyUpdate,
  fetchStampRallyExtraInfo,
} from '../../slices/stampRallySlice';
import {
  convertRichText, convertText,
  deleteLinkRichText,
  getImagesJsx,
  handlerFormikFieldChange
} from '../../utils/fnUtil';
import { mapValues } from 'lodash';
import { toSlateState } from '../richTextEditor/utils/serializer';
import { exchangeType } from '../../constants/options';
import ModalTitle from '../modalTitle';
import TcCheckbox from '../tcCheckbox';
import Button from '../tcButton';
import Yup from '../../utils/yupUtil';
import moment from 'moment';
import RichTextEditor from '../richTextEditor';
import './style.scss';
import TextBox from '../util/textBox';
import DateTimeRangePicker from '../util/dateTimeRangePicker';
import CustomNumberPicker from '../util/customNumberPicker';
import SelectBox from '../util/selectBox';
import VideoImageElement from "../videoImageElement";

/* 台紙登録モーダル */

const StampRallyModal = () => {
  const dispatch = useDispatch();

  const {
    accountInfo: { new_contents_flag, add_article_data: { video_upload_flag } },
  } = useSelector(state => state.account);

  const { stampRallyTemplateList, stampDetail, stampRallyCouponList, mode } =
    useSelector(state => state.stampRally);
  const isCopy = mode === 'copy';

  const { editModalVisible } = useSelector(state => state.stampRally);

  const initialValues = {
    stamp_rally_name: '',
    stamp_rally_text: '',
    stamp_rally_rich_text: toSlateState(stampDetail?.stamp_rally_rich_text),
    stamp_rally_image_url: '',
    use_date_from: '',
    use_hour_from: null,
    use_minute_from: null,
    use_date_to: '',
    use_hour_to: null,
    use_minute_to: null,
    valid_days_flag: '0',
    valid_days1: null,
    valid_days2: null,
    stamp_rally_count: null,
    exchange_type: '1',
    coupon_code: null,
    point: null,
    //shop_list: [],
    //brand_list: [],
    fixed_phrase: null,
    isCreate: true,
    images: Array(10).fill(''),
  };

  const yupObject = {
    stamp_rally_name: Yup.string().required().max(50),
    stamp_rally_image_url: Yup.string().required(),
    use_date_from: Yup.string().required(),
    use_hour_from: Yup.string().nullable().selected(),
    use_minute_from: Yup.string().nullable().selected(),
    use_date_to: Yup.string().required(),
    use_hour_to: Yup.string().nullable().selected(),
    use_minute_to: Yup.string().nullable().selected(),
    valid_days_flag: Yup.string().nullable().required(),
    valid_days1: Yup.string()
      .nullable()
      .test(
        'valid_days1',
        '必須',
        (value, testContext) =>
          testContext.parent.valid_days_flag !== '0' || value
      )
      .matches(/^(([1-9][0-9]*)|0)$/, '形式が異なります'),
    valid_days2: Yup.string()
      .nullable()
      .test(
        'valid_days2',
        '必須',
        (value, testContext) =>
          testContext.parent.valid_days_flag !== '1' || value
      )
      .matches(/^(([1-9][0-9]*)|0)$/, '形式が異なります'),
    stamp_rally_count: Yup.string().nullable().selected(),
    exchange_type: Yup.string().nullable().required(),
    point: Yup.string()
      .nullable()
      .test(
        'point',
        '必須',
        (value, testContext) =>
          testContext.parent.exchange_type !== '1' || value
      )
      .matches(/^(([1-9][0-9]*)|0)$/, '形式が異なります'),
    coupon_code: Yup.string()
      .nullable()
      .test(
        'coupon_code',
        '必須',
        (value, testContext) =>
          testContext.parent.exchange_type !== '2' || value
      ),
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object(yupObject),
    onSubmit: async values => {
      const {
        use_date_from,
        use_hour_from,
        use_minute_from,
        use_date_to,
        use_hour_to,
        use_minute_to,
        valid_days_flag,
        valid_days1,
        valid_days2,
        stamp_rally_text,
        stamp_rally_rich_text,
        ...rest
      } = values;

      const startDateTime = moment(use_date_from)
        .set('hour', use_hour_from)
        .set('minute', use_minute_from);

      const endDateTime = moment(use_date_to)
        .set('hour', use_hour_to)
        .set('minute', use_minute_to);

      // 利用日前後チェック
      if (startDateTime.isSameOrAfter(endDateTime)) {
        dispatch(
          globalActions.showErrorModal({
            title: 'エラー',
            message: '表示開始日は表示終了日より前の日付を入力してください',
          })
        );
        return;
      }

      const richText = deleteLinkRichText(values.stamp_rally_rich_text);

      //const shopCodeList = shop_list.map(s => s.shop_code);
      //console.log(stampDetail);
      dispatch(
        fetchStampRallyUpdate({
          ...rest,
          isCreate: isCopy ? isCopy : !stampDetail,
          valid_days_flag,
          display_start_date: startDateTime.format('YYYY/MM/DD HH:mm'),
          display_end_date: endDateTime.format('YYYY/MM/DD HH:mm'),
          valid_days: valid_days_flag === '0' ? valid_days1 : valid_days2,
          stamp_rally_rich_text: convertRichText(new_contents_flag, richText),
          stamp_rally_text: convertText(new_contents_flag, richText, stamp_rally_text),
        })
      );
    },
  });

  const {
    stamp_rally_image_url,
    valid_days_flag,
    valid_days1,
    valid_days2,
    exchange_type,
    images,
  } = formik.values;

  useEffect(() => {
    editModalVisible && dispatch(fetchStampRallyExtraInfo());
  }, [editModalVisible]);

  useUpdateEffect(
    () =>
      stampDetail &&
      formik
        .setValues({
          ...stampDetail,
          /* shop_list: stampDetail.shop_list.map(s => ({
            shop_code: s,
            shop_name: accountShopList.find(v => v.value === s)?.label,
          })), */
          stamp_rally_rich_text: toSlateState(
            stampDetail?.stamp_rally_rich_text
          ),
          template: stampRallyTemplateList?.filter(
            e => e?.template_text === stampDetail?.fixed_phrase
          )?.[0]?.template_code,
          images: stampDetail?.image_url || Array(10).fill(''),
        })
        .then(() => formik.setTouched(mapValues(yupObject, () => true)))
        .then(() => formik.validateForm()),
    [stampDetail, stampRallyTemplateList]
  );

  useUpdateEffect(() => {
    !editModalVisible && formik.resetForm();

    formik.validateForm();
  }, [editModalVisible]);

  const onBack = () => {
    dispatch(stampRallyActions.closeEditModal());
    formik.resetForm();
  };

  return (
    <Modal
      className={'stamp-modal tc-modal'}
      open={editModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={1000}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle
          title={
            isCopy ? '台紙登録(複製)' : stampDetail ? '台紙編集' : '台紙登録'
          }
          tip={'*は必須項目です'}
        />
        <div className="input-area">

          <VideoImageElement
            fieldName={"バナー画像"}
            isRequired
            btnText={"画像を追加"}
            imageUrl={stamp_rally_image_url}
            onChange={url => handlerFormikFieldChange(formik, 'stamp_rally_image_url', url)}
            uploadAction={() => formik.setFieldTouched('stamp_rally_image_url', true)}
            deleteAction={() =>  handlerFormikFieldChange(formik, 'stamp_rally_image_url', null)}
            width={150}
            height={150}
            videoUploadFlag={video_upload_flag}
          />

          <TextBox
            formik={formik}
            preLabel="台紙名"
            fieldName="stamp_rally_name"
            width={'100%'}
            isRequired
          />
          <span
            className="cus-input-line input-textarea"
            style={new_contents_flag !== 0 ? { height: 'unset' } : {}}
          >
            <label className="cus-pre-label">台紙説明</label>
            {new_contents_flag !== 0 ? (
              <div style={{ width: '100%' }}>
                <RichTextEditor
                  extraUpload={true}
                  editorState={formik.values?.stamp_rally_rich_text}
                  error={
                    formik.touched.stamp_rally_rich_text &&
                    formik.errors.stamp_rally_rich_text
                  }
                  onChange={state =>
                    formik.setFieldValue('stamp_rally_rich_text', state)
                  }
                  setTouched={() =>
                    !formik.touched.stamp_rally_rich_text &&
                    formik.setFieldTouched('stamp_rally_rich_text', true)
                  }
                  placeholder={'台紙説明を入力してください'}
                  disabled={false}
                />
              </div>
            ) : (
              <textarea
                placeholder="台紙説明を入力してください"
                spellCheck={false}
                autoComplete={'false'}
                value={formik.values.stamp_rally_text}
                onChange={e =>
                  handlerFormikFieldChange(
                    formik,
                    'stamp_rally_text',
                    e.target.value
                  )
                }
              />
            )}
          </span>
          <div className="image-area">
            <span className={'pre-label'} style={{ flexShrink: 0 }}>
              押印スタンプ画像
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <div className="uploadImageRow" style={{ marginBottom: 5 }}>
                {getImagesJsx(0, 4, images, formik, {
                  width: 118,
                  position: 'absolute',
                  bottom: 0,
                  margin: "0 16px 17px 16px",}, 150, 150)
                }
              </div>
              <div className="uploadImageRow" style={{ marginBottom: 5 }}>
                {getImagesJsx(5, 9, images, formik, {
                  width: 118,
                  position: 'absolute',
                  bottom: 0,
                  margin: "0 16px 17px 16px",}, 150, 150)
                }
              </div>
            </div>
          </div>
          <DateTimeRangePicker
            formik={formik}
            preLabel={'表示期間'}
            fromFieldName={'use_date_from'}
            fromHourFieldName={'use_hour_from'}
            fromMinuteFieldName={'use_minute_from'}
            toFieldName={'use_date_to'}
            toHourFieldName={'use_hour_to'}
            toMinuteFieldName={'use_minute_to'}
            fromUneditable={isCopy ? !isCopy : !!stampDetail}
            isRequired
            width={'100%'}
          />
          <div className="check-area">
            <span
              className={'pre-label label-required'}
              style={{
                whiteSpace: 'pre-wrap',
                marginTop: !!stampDetail ? 0 : 6,
              }}
            >
              有効期限
            </span>
            {!isCopy && !!stampDetail ? (
              <span>
                {valid_days_flag === '0'
                  ? `最新台紙初回押印日から ${valid_days1} 日`
                  : `最終押印日から ${valid_days2} 日`}
              </span>
            ) : (
              <div className="expire-container">
                <div className="input-line">
                  <TcCheckbox
                    label={'最新台紙初回押印日から'}
                    checked={valid_days_flag === '0'}
                    onChange={() =>
                      handlerFormikFieldChange(formik, 'valid_days_flag', '0')
                    }
                    isRadio={true}
                  />
                  <TextBox
                    formik={formik}
                    fieldName="valid_days1"
                    placeholder="0"
                    width={150}
                    hasLabel={false}
                    suffix={'日'}
                    style={{ marginLeft: 5 }}
                    disabled={valid_days_flag !== '0'}
                  />
                  <span className="tc-select-error">
                    {formik.touched['valid_days1'] &&
                      formik.errors['valid_days1']}
                  </span>
                </div>
                <div className="input-line">
                  <TcCheckbox
                    label={'最終押印日から'}
                    checked={valid_days_flag === '1'}
                    onChange={() =>
                      handlerFormikFieldChange(formik, 'valid_days_flag', '1')
                    }
                    isRadio={true}
                  />
                  <TextBox
                    formik={formik}
                    fieldName="valid_days2"
                    placeholder="0"
                    width={150}
                    hasLabel={false}
                    suffix={'日'}
                    style={{ marginLeft: 5 }}
                    disabled={valid_days_flag !== '1'}
                  />
                  <span className="tc-select-error">
                    {formik.touched['valid_days2'] &&
                      formik.errors['valid_days2']}
                  </span>
                </div>
              </div>
            )}
          </div>
          <CustomNumberPicker
            formik={formik}
            preLabel={'スタンプ数'}
            fieldName={'stamp_rally_count'}
            placeholder={'00'}
            width={!isCopy && stampDetail ? 140 : 300}
            from={1}
            to={100}
            uneditable={!isCopy && !!stampDetail}
            suffix={'個'}
            isRequired
          />

          {!isCopy && stampDetail ? (
            <span className="cus-input-line">
              <label className="cus-pre-label label-required">交換種別</label>
              <span>
                {exchangeType.find(o => o.value === exchange_type)?.label}
              </span>
            </span>
          ) : (
            <>
              <span className="cus-input-line input-checkbox input-exchange-area">
                <span
                  className={'pre-label label-required'}
                  style={{ whiteSpace: 'pre-wrap' }}
                >
                  交換種別
                </span>
                <div className="input-exchange-type">
                  <TcCheckbox
                    label={'ポイント'}
                    checked={exchange_type === '1'}
                    onChange={() =>
                      handlerFormikFieldChange(formik, 'exchange_type', '1')
                    }
                    isRadio={true}
                  />
                  <TcCheckbox
                    label={'クーポン'}
                    checked={exchange_type === '2'}
                    onChange={() =>
                      handlerFormikFieldChange(formik, 'exchange_type', '2')
                    }
                    isRadio={true}
                  />
                </div>
              </span>
            </>
          )}
          {exchange_type === '2' ? (
            <SelectBox
              preLabel={'クーポン'}
              formik={formik}
              fieldName="coupon_code"
              options={stampRallyCouponList}
              width={'100%'}
              uneditable={!isCopy && !!stampDetail}
              displayValue={`${stampDetail?.coupon_code} ${stampDetail?.coupon_name}`}
              isRequired
            />
          ) : (
            <TextBox
              preLabel={'ポイント数'}
              formik={formik}
              fieldName="point"
              placeholder="00"
              width={280}
              suffix={'pt'}
              isRequired
            />
          )}
          <SelectBox
            formik={formik}
            preLabel="定型文"
            fieldName="template"
            placeholder="テンプレートを選択"
            options={stampRallyTemplateList}
            width={400}
            extraOnChange={(_, v) =>
              handlerFormikFieldChange(
                formik,
                'fixed_phrase',
                stampRallyTemplateList.find(e => e.value === v)?.template_text
              )
            }
          />
          <span
            className="cus-input-line input-textarea"
            style={{ marginTop: '5px' }}
          >
            <label />
            <textarea
              placeholder=""
              spellCheck={false}
              autoComplete={'false'}
              value={formik.values.fixed_phrase}
              onChange={e =>
                handlerFormikFieldChange(formik, 'fixed_phrase', e.target.value)
              }
            />
          </span>
          <div className="button-container">
            <Button text="戻る" theme="white" onClick={onBack} />
            <Button
              disabled={!formik.isValid}
              text={isCopy ? '登録' : stampDetail ? '更新' : '登録'}
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StampRallyModal;
