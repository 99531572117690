import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useFormik } from 'formik';
import { geopushColumns } from '../../constants/columns';
import { globalActions } from '../../slices/globalSlice';
import {
  fetchGeopushList,
  geopushActions,
} from '../../slices/geopushSlice';
import {
  fetchGeopushDetail,
} from '../../slices/geopushInfoSlice';
import {
  notificationActions,
  fetchNotificationReference,
  fetchGeopushNotification,
  deleteGeopushNotification, fetchGeopushNotificationCopy,
} from '../../slices/notificationSlice';
import {
  canUpdateSendStatus,
} from '../../constants/options';
import NotificationModal from '../../components/notificationModal';
import GeopushModal from '../../components/geopushModal';
import DocumentReferenceModal from '../../components/notificationModal/reference';
import TcButton from '../../components/tcButton';
import TcCheckbox from '../../components/tcCheckbox';
import PageTitle from '../../components/pageTitle';
import TableTitle from '../../components/tableTitle';
import ColumnTitle from '../../components/columnTitle';
import TcTable from '../../components/tcTable';
import Icons from '../../constants/Icons';
import './style.scss';
import { dateFormat, handlerFormikFieldChange } from '../../utils/fnUtil';
import InputContainer from '../../components/inputContainer';
import TextBox from '../../components/util/textBox';
import SelectBox from '../../components/util/selectBox';
import DateTimeRangePicker from '../../components/util/dateTimeRangePicker';

const Geopush = () => {
  const dispatch = useDispatch();
  const parent = useRef(null);

  const { accountShopList } = useSelector(
    state => state.account
  );

  const { searchParams, geopushList, total } = useSelector(
    state => state.geopush
  );

  const formik = useFormik({
    initialValues: searchParams,
    enableReinitialize: true,
    onSubmit: values => {
      const {
        from_create_at,
        to_create_at,
        ...rest
      } = values;
      const params = {
        ...rest,
        from_create_at: dateFormat(from_create_at, 'YYYY/MM/DD'),
        to_create_at: dateFormat(to_create_at, 'YYYY/MM/DD'),
      };

      dispatch(geopushActions.saveSearchParams(params));
    },
  });

  useUpdateEffect(
    () => dispatch(fetchGeopushList(searchParams)),
    [searchParams]
  );

  useEffect(() => {
    dispatch(geopushActions.saveSearchParams(null));
    return () => dispatch(geopushActions.reset());
  }, [dispatch]);

  const columns = [
    ...geopushColumns.map(col => ({
      title: (
        <ColumnTitle
          title={col.title}
          formik={formik}
          sortField={col.sortField}
          onClick={sort => dispatch(geopushActions.saveSearchParams({ sort }))}
        />
      ),
      dataIndex: col.sortField,
      render: col.render,
    })),
    {
      title: '編集',
      align: 'center',
      width: '80px',
      render: current =>
        current.deleted_flg === '1' ? (
          <Icons.IconEditGy className="table-cell-icon" />
        ) : current.send_status &&
          !canUpdateSendStatus.includes(current.send_status) ? (
          <Icons.IconSearch
            className="table-cell-icon tc-action-icon"
            onClick={() => dispatch(fetchGeopushNotification({ document_id: current.document_id }))}
          />
        ) : (
          <Icons.IconEdit
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(
                fetchGeopushNotification({
                  document_id: current.document_id,
                })
              )
            }
          />
        ),
    },
    {
      title: '設定',
      align: 'center',
      width: '80px',
      render: current =>
        current.deleted_flg === '0' &&
          (!current.send_status ||
            (current.send_status &&
              canUpdateSendStatus.includes(current.send_status))) ? (
          <Icons.IconSettings
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(
                fetchGeopushDetail({
                  document_id: current.document_id,
                })
              )
            }
          />
        ) : (
          <Icons.IconSettingsGy className="table-cell-icon" />
        ),
    },
    {
      title: '複製',
      align: 'center',
      width: '80px',
      render: current =>
        <Icons.IconCopy
          className="table-cell-icon tc-action-icon"
          onClick={() => dispatch(fetchGeopushNotificationCopy(current))}
        />
    },
    {
      title: '削除',
      align: 'center',
      width: '80px',
      render: current =>
        current.deleted_flg === '1' ? (
          <Icons.IconSearch
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(
                fetchNotificationReference({ document_id: current.document_id })
              )
            }
          />
        ) : (
          <Icons.IconTrash
            className="table-cell-icon tc-action-icon"
            onClick={() =>
              dispatch(
                globalActions.showDeleteModal({
                  message: `${current.document_id} ${current.title}\nを削除します\nよろしいですか?`,
                  tip: '※配信済みの場合、アプリの通知一覧からも表示されなくなります',
                  deleteAction: deleteGeopushNotification({
                    document_id: current.document_id,
                  }),
                })
              )
            }
          />
        ),
    },
  ];

  return (
    <div className="content-body management-page" ref={parent}>
      <PageTitle
        title="ジオプッシュ登録・配信設定"
        icon={<Icons.IconBell className="page-title-icon" />}
      />
      <div className="search-form">
        <InputContainer parent={parent} max={800}>
          <TextBox formik={formik} label="タイトル" fieldName="title" />
          <TextBox formik={formik} label="メッセージ" fieldName="message" />
          <SelectBox
            formik={formik}
            fieldName="shop_code"
            label="店舗"
            options={accountShopList}
          />
          <DateTimeRangePicker
            formik={formik}
            label="登録日"
            fromFieldName="from_create_at"
            toFieldName="to_create_at"
          />
        </InputContainer>
        <div className="search-btn-container">
          <TcButton text={'検索'} onClick={formik.handleSubmit} />
          <TcCheckbox
            className="deleted-checkbox"
            label={'削除済み'}
            checked={formik.values.show_delete_flag === 1}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                'show_delete_flag',
                formik.values.show_delete_flag === 1 ? 0 : 1
              )
            }
          />
        </div>
      </div>
      <TableTitle
        searchCount={total}
        onClickAdd={() => {
          dispatch(notificationActions.setGeopush())
          dispatch(notificationActions.setNotificationModalVisible(true))
        }
        }
      />
      <TcTable
        columns={columns}
        dataSource={geopushList}
        offset={searchParams.offset}
        total={total}
        onChange={offset => {
          dispatch(geopushActions.saveOffset(offset));
          formik.setFieldValue('offset', offset);
        }}
      />
      <NotificationModal />
      <GeopushModal />
      <DocumentReferenceModal />
    </div>
  );
};

export default Geopush;
