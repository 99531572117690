import React, { useState } from 'react';
import classNames from 'classnames';
import { Modal } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { flagOptions } from '../../constants/options';
import { contentsActions } from '../../slices/contentsSlice';
import ModalTitle from '../modalTitle';
import Button from '../../components/tcButton';
import Icon from '@ant-design/icons';
import Icons from '../../constants/Icons';
import ImagesReference from '../imagesReference';
import { toSlateState } from '../richTextEditor/utils/serializer';
import RichTextEditor from '../richTextEditor';
import './style.scss';
import VideoImageTagElement from "../videoImageTagElement";
import {convertImageFieldName} from "../../utils/fnUtil";

/* コンテンツ参照モーダル */

const ContentsReferenceModal = () => {
  const dispatch = useDispatch();

  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const {
    accountInfo: { new_contents_flag, add_article_data: { video_upload_flag } },
  } = useSelector(state => state.account);

  const { referenceModalVisible, referenceContents } = useSelector(
    state => state.contents
  );

  const initialValues = {
    start_post_period_date: null,
    start_post_period_hour: null,
    start_post_period_minutes: null,
    end_post_period_date: null,
    end_post_period_hour: null,
    end_post_period_minutes: null,
    display_order: null,
    contents_type: '1',
    title: null,
    sub_title: null,
    slider: '0',
    notice_flag: '0',
    link: null,
    banner_image_url: null,
    text: '',
    image_url: Array(9).fill(''),
  };

  const {
    start_post_period,
    end_post_period,
    display_order,
    title,
    sub_title,
    slider,
    notice_flag,
    link,
    banner_image_url,
    text,
    rich_text,
    image_url,
  } = referenceContents || initialValues;

  useUpdateEffect(() => {
    setIsDetailOpen(text || rich_text || image_url?.filter(i => i)?.length > 0);
  }, [referenceContents]);

  const onBack = () => {
    dispatch(contentsActions.closeReferenceModal());
  };

  const dateToString = (date, format) => moment(date)?.format(format) || '';
  const rangeDateString = (from, to) => {
    const fromString = dateToString(from, 'YYYY/M/D HH:mm');
    const toString = dateToString(to, 'YYYY/M/D HH:mm');
    return `${fromString} ~ ${toString}`;
  };

  return (
    <Modal
      className={'contents-modal tc-modal'}
      open={referenceModalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={900}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <div className="tc-modal-content">
        <ModalTitle title={'コンテンツ参照'} />
        <div className="input-area">
          <div className="reference-area">
            <span className={'pre-label'}>掲載期間</span>
            <span className="font-sm">
              {rangeDateString(start_post_period, end_post_period)}
            </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>表示優先順</span>
            <span className="font-sm">{display_order} </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>タイトル</span>
            <span className="font-sm">{title} </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>サブタイトル</span>
            <span className="font-sm">{sub_title} </span>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>スライダー</span>
            <span className="font-sm">
              {flagOptions?.filter(e => e.value === slider)?.[0]?.label}
            </span>
          </div>
          <div className="check-area">
            <span className={'pre-label'}>プッシュ通知配信</span>
            <span className="font-sm">
              {flagOptions?.filter(e => e.value === notice_flag)?.[0]?.label}
            </span>
          </div>
          <div className="reference-area">
            <span className={'pre-label'}>外部リンク</span>
            <span className="font-sm">{link} </span>
          </div>
          <div className="image-area">
            <span className={'pre-label'}>
              {convertImageFieldName(video_upload_flag, "バナー画像")}
            </span>
            <div className="image-border">
              <VideoImageTagElement imageUrl={banner_image_url} />
            </div>
          </div>
          <div
            className="detail-area-title"
            onClick={() => setIsDetailOpen(!isDetailOpen)}
          >
            <div>詳細画面</div>
            <Icon
              component={
                isDetailOpen ? Icons.IconChevronUp : Icons.IconChevronDown
              }
              className="arrow-icon"
            />
          </div>
          <div
            className={classNames({
              'detail-area': true,
              hidden: !isDetailOpen,
            })}
          >
            <div className="text-area">
              <label className="text-required">本文</label>
              {new_contents_flag !== 0 ? (
                <div className="text-area" style={{ flexGrow: 1 }}>
                  <span className="editor-detail">
                    <RichTextEditor
                      readOnly={true}
                      editorState={toSlateState(rich_text)}
                    />
                  </span>
                </div>
              ) : (
                <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>
              )}
            </div>
            <div className="image-area">
              <label className="text-required">画像</label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '80%',
                }}
              >
                <ImagesReference
                  image_url={image_url}
                  from={0}
                  to={2}
                  width={180}
                  height={200}
                />
                <ImagesReference
                  image_url={image_url}
                  from={3}
                  to={5}
                  width={180}
                  height={200}
                />
                <ImagesReference
                  image_url={image_url}
                  from={6}
                  to={8}
                  width={180}
                  height={200}
                />
              </div>
            </div>
          </div>
          <div className="button-container">
            <Button
              text="閉じる"
              theme="white"
              onClick={() => dispatch(contentsActions.closeReferenceModal())}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContentsReferenceModal;
