import { Modal } from 'antd';
import TcCheckbox from '../../tcCheckbox';
import Icons from '../../../constants/Icons';
import Button from '../../tcButton';
import ModalTitle from '../../modalTitle';
import Yup from '../../../utils/yupUtil';
import { globalActions } from '../../../slices/globalSlice';
import { useUpdateEffect } from 'react-use';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { handlerFormikFieldChange } from '../../../utils/fnUtil';
import {
  pageAccessCsvDownloadModeOptions,
} from '../../../constants/options';
import './style.scss';

/* CSV出力モダール */

const PageAccessCsvDownloadModal = ({
  modalVisible,
  options,
  favoriteItem = [],
  onClose,
  onDownload,
  onFavoriteItem,
  line = 1,
  width = 800,
  tip = '',
  requiredText = '出力する項目を選択してください',
  searchArea,
  disabled = false,
  contentsMode = null,
}) => {
  const buttonStyle = {
    width: 140,
    height: 30,
    fontSize: 14,
    marginTop: 40,
  };

  const optionsDouble = (() => {
    const newList = [[]];
    for (const option of options) {
      const last = newList[newList.length - 1];
      if (last.length === line) {
        newList.push([option]);
      } else {
        last.push(option);
      }
    }
    return newList;
  })();

  const [checkedList, setCheckedList] = useState([]);
  const [checkAllFlag, setCheckAllFlag] = useState(false);

  const dispatch = useDispatch();

  const initialValues = {
    mode: '0',
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: true,
    validationSchema: Yup.object({
      mode: Yup.string().required(),
    }),
    onSubmit: async values => {
      const {
        start_post_period_date,
        start_post_period_hour,
        start_post_period_minutes,
        end_post_period_date,
        end_post_period_hour,
        end_post_period_minutes,
        ...rest
      } = values;

      const params = {
        ...rest,
      };
      // dispatch(isUpdate ? contentsUpdate(params) : contentsCreate(params));
    },
  });

  useUpdateEffect(() => {
    const checkList = (() => {
      const tmpCheckList = [];
      for (const option in favoriteItem) {
        if (favoriteItem[option] === '1') tmpCheckList.push(option);
      }
      return tmpCheckList;
    })();
    setCheckedList(checkList);
    setCheckAllFlag(options.filter(e => e?.value).length === checkList.length);
  }, [modalVisible, favoriteItem]);

  useUpdateEffect(() => {
    // TENCO-786 searchAreaのラジオボタンの挙動を検知しチェックボックスを操作
    if (contentsMode) {
      const result = optionsDouble[0].every(item => checkedList.includes(item.value));
      setCheckAllFlag(result);
    }
  }, [contentsMode, checkedList]);

  const onCheckAllChange = e => {
    const checkVals = options.filter(e => e).map(option => option?.value);
    setCheckedList(e.target.checked ? checkVals : []);
    setCheckAllFlag(!checkAllFlag);
  };

  const checkBoxesOnChange = (value, check) => {
    const checkList = check
      ? [...checkedList, value]
      : checkedList.filter(e => e !== value);
    setCheckedList(checkList);
    setCheckAllFlag(options.filter(e => e?.value).length === checkList.length);
  };

  const createCheckBox = option => {
    return option ? (
      <TcCheckbox
        key={option.value}
        label={option.label}
        onChange={e => checkBoxesOnChange(option.value, e.target.checked)}
        checked={checkedList?.find(e => e === option.value)?.length > 0}
        className="check-box"
        wrapperStyle={{ width: `${100 / line}%` }}
      />
    ) : (
      <></>
    );
  };

  return (
    <>
      <Modal
        open={modalVisible}
        centered
        footer={null}
        closable={false}
        onCancel={onClose}
        width={width}
      >
        <div className="csvDownloadModal-content">
          <ModalTitle title="CSV出力項目" tip={tip} />
          {!!requiredText && (
            <div className="description-area">
              <label>{requiredText}</label>
              <Button
                onClick={() => {
                  if (checkedList.length < 1) {
                    dispatch(
                      globalActions.showErrorModal({
                        title: 'エラー',
                        message: '項目を1つ以上選択してください',
                      })
                    );
                    return;
                  }
                  onFavoriteItem(checkedList);
                }}
                text={'お気に入り項目設定'}
                theme={'white'}
                icon={<Icons.IconBookmark className="btn-icon" />}
                style={{
                  fontSize: 14,
                  margin: 0,
                  marginTop: 5,
                  width: 200,
                }}
              />
            </div>
          )}
          {!!searchArea && (
            <div className="search-area-wrapper">{searchArea}</div>
          )}
          {!!searchArea && <div className="border-line" />}
          <div className="contents-area">
            {!!searchArea && (
              <div className="description-area">
                <label>出力する項目を選択してください</label>
                <Button
                  onClick={() => {
                    if (checkedList.length < 1) {
                      dispatch(
                        globalActions.showErrorModal({
                          title: 'エラー',
                          message: '項目を1つ以上選択してください',
                        })
                      );
                      return;
                    }
                    onFavoriteItem(checkedList);
                  }}
                  text={'お気に入り項目設定'}
                  theme={'white'}
                  icon={<Icons.IconBookmark className="btn-icon" />}
                  style={{
                    fontSize: 14,
                    margin: 0,
                    marginTop: 5,
                    width: 200,
                  }}
                />
              </div>
            )}
            <TcCheckbox
              label={'全選択'}
              onChange={onCheckAllChange}
              checked={checkAllFlag}
              className="all-check-box"
              bold={true}
            />
            <div className="checkbox-group">
              {optionsDouble.map((options, i) => (
                <div key={i} className="checkbox-line">
                  {[...Array(line)].map((e, i) => createCheckBox(options?.[i]))}
                </div>
              ))}
            </div>
          </div>
          <div className="button-area">
            <Button
              text="閉じる"
              onClick={onClose}
              style={buttonStyle}
              theme={'white'}
            />
            <Button
              text="ダウンロード"
              onClick={() => onDownload(formik.values.mode, checkedList)}
              disabled={disabled || checkedList?.length === 0}
              style={buttonStyle}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PageAccessCsvDownloadModal;
