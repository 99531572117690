import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axiosUtil';
import { globalActions } from './globalSlice';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment';
import qs from 'qs';

const initialSearchParams = {
  title: '',
  sub_title: '',
  slider: null,
  start_post_period: null,
  end_post_period: null,
  deleted_flg: '0',
  offset: 0,
  count: 10,
  sort: 'topimage_code_desc',
  mode: "0"
};

const initialState = {
  searchParams: initialSearchParams,
  topImageList: [],
  total: 0,
  deleteModalVisible: false,
  editModalVisible: false,
  referenceModalVisible: false,
  deleteTopImage: null,
  currentTopImage: null,
  csvDownloadModalVisible: false,
  resultModalVisible: false,
  pageAccessResult: null,
  referenceTopImage: null,
  favoriteItem: [],
};

export const fetchTopImageList = createAsyncThunk(
  'fetchTopImageList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('topimage/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));
    return result;
  }
);

export const fetchTopImageDetail = createAsyncThunk(
  'fetchTopImageDetail',
  async params =>
    await axios.get('topimage/detail', {
      params: { topimage_code: params.topimage_code },
    })
);

export const fetchTopImageReference = createAsyncThunk(
  'fetchTopImageReference',
  async params =>
    await axios.get('topimage/detail', {
      params: { topimage_code: params.topimage_code },
    })
);

export const topImageCreate = createAsyncThunk(
  'topImageCreate',
  async (params, { dispatch }) => {
    await axios.post('topimage/create', params);
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const topImageUpdate = createAsyncThunk(
  'topImageUpdate',
  async (params, { dispatch }) => {
    await axios.post('topimage/update', params);
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const topImageDelete = createAsyncThunk(
  'topImageDelete',
  async params => await axios.post('topimage/delete', { topimage_code: params })
);

export const fetchTopImageCsvFavoriteItemList = createAsyncThunk(
  'fetchTopImageCsvFavoriteItemList',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchTopImageCsvFavoriteItemCreate = createAsyncThunk(
  'fetchTopImageCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post(
      'csv/favorite_item/create',
      params
    );

    updateFavoriteRes?.code === '0' &&
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));
    return params?.favorite;
  }
);

export const downloadCsvTopImageAccess = createAsyncThunk(
  'downloadCsvTopImageAccess',
  async (params, { dispatch }) => {
    const result = await axios.get('csv/download', {
      params,
      paramsSerializer: params => qs.stringify(params, { allowDots: true }),
    });
    if (!!!result) {
      dispatch(globalActions.showCommonErrorModal());
    }
    return result;
  }
);

export const fetchPageAccessResult = createAsyncThunk(
  'fetchPageAccessResult',
  async params => await axios.get('sum/contents_access', { params })
);

export const topImageSlice = createSlice({
  name: 'topImageSlice',
  initialState,
  reducers: {
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams['offset'] = payload;
    },
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      deleteTopImage: payload,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
      deleteTopImage: null,
    }),
    openEditModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentTopImage: null,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceModalVisible: false,
      currentTopImage: null,
    }),
    openCsvDownloadModal: (state, { payload }) => ({
      ...state,
      currentContents: payload,
      csvDownloadModalVisible: true,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    openResultModal: (state, { payload }) => ({
      ...state,
      resultModalVisible: true,
    }),
    closeResultModal: state => ({
      ...state,
      resultModalVisible: false,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchTopImageList.fulfilled]: (state, { payload }) => {
      state.topImageList = payload.item;
      state.total = payload.total_count;
    },
    [fetchTopImageDetail.fulfilled]: (state, { payload }) => {
      state.editModalVisible = true;
      state.currentTopImage = {
        ...payload.item,
        richText: payload.item?.rich_text
          ? payload.item.rich_text
            .split('-----separator-----')
            .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
          : [],
      };
    },
    [fetchTopImageReference.fulfilled]: (state, { payload }) => {
      state.referenceModalVisible = true;
      state.referenceTopImage = {
        ...payload.item,
        richText: payload.item?.rich_text
          ? payload.item.rich_text
            .split('-----separator-----')
            .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
          : [],
      };
    },
    [topImageCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
    }),
    [topImageUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
      currentTopImage: null,
    }),
    [topImageDelete.fulfilled]: state => ({
      ...state,
      deleteModalVisible: false,
      searchParams: { ...state.searchParams },
      deleteTopImage: null,
    }),
    [fetchTopImageCsvFavoriteItemList.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchTopImageCsvFavoriteItemCreate.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload },
    }),
    [downloadCsvTopImageAccess.fulfilled]: (_, { payload }) => {
      if (payload && typeof payload === 'string') {
        downloadFile(
          payload,
          `コンテンツアクセス集計_${moment().format('YYYYMMDDHHmmss')}.csv`
        );
      }
    },
    [fetchPageAccessResult.fulfilled]: (state, { payload }) => {
      state.pageAccessResult = payload.item;
      state.resultModalVisible = true;
    },
  },
});

export const topImageActions = topImageSlice.actions;

export default topImageSlice.reducer;
