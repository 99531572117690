import React from 'react';
import {
  getFormikArrayProps,
  getOptionLabel,
  handlerFormikFieldChange,
} from '../../utils/fnUtil';
import { Tooltip } from 'antd';
import TcCheckbox from '../tcCheckbox';
import TcRadio from '../tcCheckbox/tcRadio';
import {
  answerTypeOptions,
  flagOptions2,
  flagOptions3,
  graphTypeOptions,
  summaryFlagTypes,
} from '../../constants/options';
import Icons from '../../constants/Icons';
import './style.scss';
import AnswerList from './answer';
import TextBox from '../util/textBox';
import VideoImageElement from "../videoImageElement";

const QuestionList = ({ formik, initial, isUpdate }) => {
  const { question_list } = formik.values;
  const question_count_min = 1;
  const answer_type_flag = 3;

  const summaryFlagError = i => {
    const props = getFormikArrayProps(
      formik,
      `question_list.${i}.summary_flag`
    );
    return props.touched ? props.error : null;
  };

  const summayList = Object.entries(summaryFlagTypes);

  return (
    <div className="question-list">
      {question_list?.map((_, i) => (
        <div className="question-border">
          <div className="delete-btn-area">
            {!isUpdate && question_list?.length > question_count_min && (
              <Icons.IconXCircleWh
                onClick={async () => {
                  const values = {
                    ...formik.values,
                    question_list: [
                      ...formik.values['question_list']?.filter(
                        (_, index) => index !== i
                      ),
                    ],
                  };
                  formik.setValues(values);
                  formik.setTouched({
                    ...formik.touched,
                    question_list: formik.touched['question_list']?.filter(
                      (_, index) => index !== i
                    ),
                  });

                  const errors = await formik.validateForm(values);

                  formik.setErrors(errors);
                }}
              />
            )}
          </div>
          <div className="question-input-area">
            <VideoImageElement
              fieldName={"画像"}
              btnText={"画像を追加"}
              imageUrl={question_list[i].image_url}
              onChange={url => handlerFormikFieldChange(formik, `question_list.${i}.image_url`, url)}
              uploadAction={() => formik.setFieldTouched(`question_list.${i}.image_url`, true)}
              deleteAction={() =>  handlerFormikFieldChange(formik, `question_list.${i}.image_url`, null)}
              width={150}
              height={150}
              videoUploadFlag={0}
            />

            <TextBox
              formik={formik}
              preLabel="設問名"
              listFieldName={`question_list.${i}.question_name`}
              width={'100%'}
              isRequired
            />

            <span className="cus-input-line input-checkbox">
              {isUpdate ? (
                <>
                  <span className="pre-label label-required">回答必須</span>
                  {getOptionLabel(
                    flagOptions2,
                    formik.getFieldProps(`question_list.${i}.required_flag`)
                      .value
                  )}
                </>
              ) : (
                <TcRadio
                  formik={formik}
                  label={'回答必須'}
                  isRadio={true}
                  fieldName={`question_list.${i}.required_flag`}
                  width={150}
                  required={true}
                  options={flagOptions2}
                  onChecked={option =>
                    formik.getFieldProps(`question_list.${i}.required_flag`)
                      .value === option.value
                  }
                />
              )}
            </span>

            <span className="cus-input-line input-checkbox">
              {isUpdate ? (
                <>
                  <span className="pre-label label-required">回答区分</span>
                  {getOptionLabel(
                    answerTypeOptions,
                    formik.getFieldProps(`question_list.${i}.answer_type`).value
                  )}
                </>
              ) : (
                <TcRadio
                  formik={formik}
                  label={'回答区分'}
                  isRadio={true}
                  fieldName={`question_list.${i}.answer_type`}
                  width={495}
                  required={true}
                  options={answerTypeOptions}
                  onChecked={option =>
                    formik.getFieldProps(`question_list.${i}.answer_type`)
                      .value === option.value
                  }
                />
              )}
            </span>

            {Number(
              formik.getFieldProps(`question_list.${i}.answer_type`).value
            ) < answer_type_flag && (
              <>
                {isUpdate ? (
                  <span className="input-checkbox answer-area">
                    <span className="pre-label label-required">回答選択肢</span>
                    <div className="answer-area-item">
                      {formik
                        .getFieldProps(`question_list.${i}.answer_list`)
                        .value.map(v => v.answer_text)
                        .join(',')}
                    </div>
                  </span>
                ) : (
                  <AnswerList
                    formik={formik}
                    fieldName={`question_list.${i}.answer_list`}
                  />
                )}

                <span className="cus-input-line input-checkbox">
                  {isUpdate ? (
                    <div className="result-display">
                      <span className="pre-label label-required">
                        回答結果表示方法
                      </span>
                      {getOptionLabel(
                        flagOptions3,
                        formik.getFieldProps(
                          `question_list.${i}.answer_result_display_flag`
                        ).value
                      )}
                    </div>
                  ) : (
                    <TcRadio
                      formik={formik}
                      label={'回答結果表示方法'}
                      isRadio={true}
                      fieldName={`question_list.${i}.answer_result_display_flag`}
                      width={183}
                      required={true}
                      options={flagOptions3}
                      onChecked={option =>
                        formik.getFieldProps(
                          `question_list.${i}.answer_result_display_flag`
                        ).value === option.value
                      }
                    />
                  )}
                </span>
                <span className="cus-input-line input-checkbox">
                  {isUpdate ? (
                    <>
                      <span className="pre-label label-required">
                        グラフタイプ
                      </span>
                      {getOptionLabel(
                        graphTypeOptions,
                        formik.getFieldProps(`question_list.${i}.graph_type`)
                          .value
                      )}
                    </>
                  ) : (
                    <TcRadio
                      formik={formik}
                      label={'グラフタイプ'}
                      isRadio={true}
                      fieldName={`question_list.${i}.graph_type`}
                      width={366}
                      required={true}
                      options={graphTypeOptions}
                      onChecked={option =>
                        formik.getFieldProps(`question_list.${i}.graph_type`)
                          .value === option.value
                      }
                    />
                  )}
                </span>

                <span className="check-area input-checkbox">
                  <span className="pre-label label-required">集計処理</span>
                  {isUpdate ? (
                    <>
                      {Object.entries(
                        formik.getFieldProps(`question_list.${i}.summary_flag`)
                          .value
                      )
                        .filter(([_, v]) => !!Number(v))
                        .map(([k, _]) => summaryFlagTypes[k])
                        .join(',')}
                    </>
                  ) : (
                    <>
                      <span className="input-error">{summaryFlagError(i)}</span>
                      {summayList.map(([k, v], index) => (
                        <TcCheckbox
                          label={v}
                          checked={
                            formik.getFieldProps(
                              `question_list.${i}.summary_flag.${k}`
                            ).value !== '0'
                          }
                          wrapperStyle={
                            index === 0
                              ? {}
                              : { marginLeft: '30px', whiteSpace: 'nowrap' }
                          }
                          onChange={() =>
                            handlerFormikFieldChange(
                              formik,
                              `question_list.${i}.summary_flag.${k}`,
                              formik.getFieldProps(
                                `question_list.${i}.summary_flag.${k}`
                              ).value === '0'
                                ? '1'
                                : '0'
                            )
                          }
                        />
                      ))}
                    </>
                  )}
                </span>
              </>
            )}
          </div>
        </div>
      ))}
      {!isUpdate && (
        <div className="add-btn-area">
          <Tooltip
            title="アンケート枠追加"
            color="#C4A6A9"
            overlayClassName="add-tooltip"
          >
            <Icons.IconPlus
              className="plus-icon"
              onClick={() =>
                formik.setFieldValue('question_list', [
                  ...question_list,
                  initial,
                ])
              }
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default QuestionList;
