import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { globalActions } from './globalSlice';
import axios from '../utils/axiosUtil';
import { downloadFile } from '../utils/fnUtil';
import moment from 'moment';
import { couponTypes } from '../constants/options';

const initialSearchParams = {
  gacha_code: '',
  gacha_name: '',
  campaign_start_date: null,
  campaign_end_date: null,
  gacha_start_date: null,
  gacha_end_date: null,
  trigger: null,
  deleted_flg: 0,
  offset: 0,
  count: 10,
  sort: 'gacha_code_desc',
};

const initialState = {
  searchParams: initialSearchParams,
  total: 0,
  editModalVisible: false,
  currentGacha: null,
  csvGacha: null,
  deleteGacha: null,
  referenceGacha: null,
  applicant: null,
  favoriteItem: [],
  couponList: [],
  templateList: [],
  maxNumber: 1,
  geoOffset: 0,
  activeStamp: null, //有効なスタンプをセットする
  mode: ""
};

export const fetchGachaTemplateList = createAsyncThunk(
  'fetchGachaTemplateList',
  async () => {
    const TemplateRes = await axios.get('/settings/template/list', {params: {template_type: 4}});
    return {
      templateList: (TemplateRes?.item ?? []).map(t => ({
        ...t,
        label: t.template_title,
        value: t.template_code,
        template_text: t.template_text,
      })),
    };
  }
);

export const fetchGachaCouponList = createAsyncThunk(
  'fetchCouponList',
  async params =>
    await axios.get('/coupon/list', {
      params: {
        ...params,
        coupon_type: couponTypes['gacha'],
      },
    })
);

export const fetchGachaCsvFavoriteItem = createAsyncThunk(
  'fetchGachaCsvFavoriteItem',
  async params => axios.get('csv/favorite_item/list', { params })
);

export const fetchGachaCsvFavoriteItemCreate = createAsyncThunk(
  'fetchGachaCsvFavoriteItemCreate',
  async (params, { dispatch }) => {
    const response = await axios.post('csv/favorite_item/create', params);

    if (response?.code === '0')
      dispatch(globalActions.showSingleModal('お気に入り登録が完了しました'));

    return response.params;
  }
);

export const fetchGachaCsvDownload = createAsyncThunk(
  'fetchGachaCsvDownload',
  async params => await axios.get('csv/download', { params })
);

export const fetchGachaList = createAsyncThunk(
  'fetchGachaList',
  async (params, { dispatch }) => {
    dispatch(globalActions.setPrimaryLoading(true));

    const result = await axios.get('gacha/list', { params });

    dispatch(globalActions.setPrimaryLoading(false));

    return result;
  }
);

export const fetchGachaDetail = createAsyncThunk(
  'fetchGachaDetail',
  async params =>
    await axios.get('gacha', {
      params: { gacha_code: params.gacha_code },
    })
);

export const fetchGachaCopy = createAsyncThunk(
  'fetchGachaCopy',
  async params =>
    await axios.get('gacha', {
      params: { gacha_code: params.gacha_code },
    })
);

export const fetchGachaReference = createAsyncThunk(
  'fetchGachaReference',
  async params =>
    await axios.get('gacha', {
      params: { gacha_code: params.gacha_code },
    })
);

export const fetchGachaResult = createAsyncThunk(
  'fetchGachaResult',
  async params =>
    await axios.get('gacha/reference', {
      params: { gacha_code: params.gacha_code },
    })
);

export const fetchGachaCreate = createAsyncThunk(
  'fetchGachaCreate',
  async (params, { dispatch }) => {
    await axios.post('gacha/create', { ...params });
    dispatch(globalActions.showSingleModal('登録が完了しました'));
  }
);

export const fetchGachaUpdate = createAsyncThunk(
  'fetchGachaUpdate',
  async (params, { dispatch }) => {
    await axios.post('gacha/update', { ...params });
    dispatch(globalActions.showSingleModal('更新が完了しました'));
  }
);

export const fetchGachaDelete = createAsyncThunk(
  'fetchGachaDelete',
  async params => await axios.post('gacha/delete', { ...params })
);

export const gachaSlice = createSlice({
  name: 'gachaSlice',
  initialState,
  reducers: {
    openEditModal: state => ({
      ...state,
      editModalVisible: true,
      maxNumber: 1,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteGacha: payload,
    }),
    openCsvDownloadModal: (state, { payload }) => ({
      ...state,
      csvGacha: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      currentGacha: null,
      mode: "",
      maxNumber: 1,
    }),
    closeCsvDownloadModal: state => ({
      ...state,
      csvGacha: null,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteGacha: null,
    }),
    closeReferenceModal: state => ({
      ...state,
      referenceGacha: null,
    }),
    closeResultModal: state => ({
      ...state,
      applicant: null,
    }),
    saveSearchParams: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        offset: payload,
      },
    }),
    saveGeoOffset: (state, { payload }) => ({
      ...state,
      geoOffset: payload,
    }),
    clear: () => initialState,
  },
  extraReducers: {
    [fetchGachaTemplateList.fulfilled]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [fetchGachaCsvFavoriteItem.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.item?.favorite },
    }),
    [fetchGachaCsvFavoriteItemCreate.fulfilled]: (state, { payload }) => ({
      ...state,
      favoriteItem: { ...payload?.favorite },
    }),
    [fetchGachaCsvDownload.fulfilled]: (_state, { payload }) =>
      typeof payload === 'string' &&
      downloadFile(
        payload,
        `ガチャ一覧_${moment().format('YYYYMMDDHHmmss')}.csv`
      ),
    [fetchGachaList.fulfilled]: (state, { payload }) => ({
      ...state,
      total: payload?.total_count ?? 0,
      gachaList: [
        ...payload?.item?.gacha_list.map(gacha => {
          const {
            campaign_start_date,
            campaign_end_date,
            gacha_start_date,
            gacha_end_date,
            gacha_code,
            gacha_name,
            trigger,
            status,
            create_at,
            deleted_flg,
          } = gacha;

          return {
            gacha_code: gacha_code,
            gacha_name: gacha_name,
            trigger: trigger,
            campaign_start_date: campaign_start_date,
            campaign_end_date: campaign_end_date,
            gacha_start_date: gacha_start_date,
            gacha_end_date: gacha_end_date,
            status: status,
            create_at: create_at,
            deleted_flg: deleted_flg,
          };
        }),
      ],
    }),
    [fetchGachaDetail.fulfilled]: (state, { payload }) => {
      const { deleted_flg, gacha_rich_content, locations, gift_list, ...rest } =
        payload?.item;
      const gifts = gift_list.some(v => v.rank == '9')
          ? gift_list.map((v, i) => ({
            ...v,
            is_checked: true,
          }))
          : [...gift_list.map(v => ({
            ...v,
            is_checked: true,
          })),
            {
              rank: '9',
              coupon_code: null,
              stamp_count: null,
              point: null,
              max_winner_count: null,
              winner_probability: 0,
              is_checked: false
          }]
      return {
        ...state,
        editModalVisible: true,
        currentGacha: {
          ...rest,
          locations: locations?.map(l => ({ ...l, reflect: true })),
          locations_edit: locations?.map(l => ({ ...l, reflect: true })),
          locations_temp: locations?.map(l => ({ ...l, reflect: true })),
          gift_list: gifts,
          maxNumber: Math.max(
            ...(locations ??
              [].map(item => parseInt(item?.identifier ?? '001', 10)))
          ),
          gacha_rich_content: gacha_rich_content
            ? gacha_rich_content
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        },
        maxNumber: Array.isArray(locations) && locations.length
          ? Math.max(...locations.map(item => parseInt(item?.identifier ?? '001', 10)))
          : 1,
      };
    },
    [fetchGachaCopy.fulfilled]: (state, { payload }) => {
      const { deleted_flg, gacha_rich_content, locations, gift_list, ...rest } =
        payload?.item;
      const gifts = gift_list.some(v => v.rank == '9')
        ? gift_list.map((v, i) => ({
          ...v,
          is_checked: true,
        }))
        : [...gift_list.map(v => ({
          ...v,
          is_checked: true,
        })),
          {
            rank: '9',
            coupon_code: null,
            stamp_count: null,
            point: null,
            max_winner_count: null,
            winner_probability: 0,
            is_checked: false
          }]
      return {
        ...state,
        editModalVisible: true,
        mode: "copy",
        currentGacha: {
          ...rest,
          locations: locations?.map(l => ({ ...l, reflect: true })),
          locations_edit: locations?.map(l => ({ ...l, reflect: true })),
          locations_temp: locations?.map(l => ({ ...l, reflect: true })),
          gift_list: gifts,
          maxNumber: Math.max(
            ...(locations ??
              [].map(item => parseInt(item?.identifier ?? '001', 10)))
          ),
          gacha_rich_content: gacha_rich_content
            ? gacha_rich_content
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
        },
        maxNumber: Array.isArray(locations) && locations.length
          ? Math.max(...locations.map(item => parseInt(item?.identifier ?? '001', 10)))
          : 1,
      };
    },
    [fetchGachaReference.fulfilled]: (state, { payload }) => {
      const { deleted_flg, gacha_rich_content, ...rest } = payload?.item;

      return {
        ...state,
        referenceGacha: {
          ...rest,
          gacha_rich_content: gacha_rich_content
            ? gacha_rich_content
                .split('-----separator-----')
                .map((str, idx) => ({ sort_num: idx + 1, dom: str }))
            : [],
          lottery_method: rest.gift_list.some(v => !!v.max_winner_count)
            ? '0'
            : '1',
        },
      };
    },
    [fetchGachaResult.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        applicant: payload?.item?.response,
      };
    },
    [fetchGachaCreate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      searchParams: { ...state.searchParams },
      mode: "",
      currentGacha: null,
    }),
    [fetchGachaUpdate.fulfilled]: state => ({
      ...state,
      editModalVisible: false,
      currentGacha: null,
      searchParams: { ...state.searchParams },
    }),
    [fetchGachaDelete.fulfilled]: state => ({
      ...state,
      deleteGacha: null,
      searchParams: { ...state.searchParams },
    }),
  },
});

export const gachaActions = gachaSlice.actions;

export default gachaSlice.reducer;
