import React from 'react';
import Icon from '@ant-design/icons';
import Icons from '../../constants/Icons';
import BaseUpload from '../baseUpload';
import Button from '../tcButton';
import classNames from 'classnames';
import VideoImageTagElement from '../videoImageTagElement';
import {convertImageBtnText, convertImageFieldName} from "../../utils/fnUtil";

const VideoImageElement = ({
  fieldName,
  isRequired,
  btnText,
  imageUrl,
  onChange,
  uploadAction,
  deleteAction,
  width,
  height,
  disabled,
  videoUploadFlag,
}) => {
  return (
    <div className="image-area">
      <span
        className={classNames(
          `pre-label${isRequired ? ' label-required' : ''}`,
          {
            'label-disabled': disabled,
          }
        )}
      >
        {convertImageFieldName(videoUploadFlag, fieldName)}
      </span>
      <div className="image-select">
        {imageUrl && (
          <Icon
            component={Icons.IconXCircleWh}
            className="close-icon"
            onClick={e => {
              deleteAction && deleteAction();
              e.stopPropagation();
            }}
          />
        )}
        {imageUrl ? (
          <VideoImageTagElement
            imageUrl={imageUrl}
            width={width}
            height={height}
          />
        ) : (
          <BaseUpload
            disabled={disabled}
            onChange={url => onChange && onChange(url)}
            onClick={() => uploadAction && uploadAction()}
            videoUploadFlag={videoUploadFlag}
          >
            <Button text={convertImageBtnText(videoUploadFlag, btnText)} theme="white" disabled={disabled} />
          </BaseUpload>
        )}
      </div>
    </div>
  );
};

export default VideoImageElement;
